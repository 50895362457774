<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { orderBy } from 'lodash-es';
import { reactive } from 'vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import NotesEditor from '~/terra/molecules/note-editor.vue';

const props = defineProps({
  notes: {
    type: Array,
    default: () => ([]),
    required: true,
  },
  attachment_config: {
    type: Object,
  },
  on_update: {
    type: Function,
    default: () => {},
  },
  show_editor: {
    type: Boolean,
    default: true,
  },
  options: {
    type: Object,
    default: () => ({}),
  },
});

const { $t, $date, auth_store } = useCommonImports();

const HawkWysiwygEditorComponent = defineAsyncComponent(() => import('~/common/components/organisms/hawk-wysiwyg-editor/hawk-wysiwyg-editor.vue'));

const state = reactive({
  is_deleting: false,
  edit_note: null,
});

function checkIsEditable(note) {
  if (note.is_progress_history || state.edit_note?.uid === note.uid)
    return false;
  return getOwner(note) === auth_store.logged_in_user_id;
}

function sortByDate(data) {
  return orderBy((data || []), [p => p.created_at], ['asc']);
}

function getOwner(note) {
  return note?.created_by || note?.owner?.uid;
}

async function onDelete(note) {
  try {
    state.is_deleting = true;
    const notes = { remove: [note.uid] };
    const attachments = { remove: note.attachments.map(attachment => attachment.uid) };
    await props.on_update({ notes, attachments });
    state.is_deleting = false;
  }
  catch (error) {
    logger.error(error);
    state.is_deleting = false;
  }
}
</script>

<template>
  <div>
    <div v-for="note in sortByDate(notes)" :key="note.uid" class="cursor-pointer group/note p-2 hover:bg-gray-50 rounded-lg">
      <div class="flex items-start w-full mb-1">
        <HawkMembers :members="getOwner(note)" class="mr-2" />
        <div class="w-full">
          <div class="flex justify-between">
            <div class="flex items-center justify-start flex-wrap gap-x-1">
              <HawkMembers :members="getOwner(note)" type="label" :has_avatar="false" name_classes="text-sm font-semibold" />
              <p v-if="note.created_at" class="text-xs text-gray-600">
                {{ $date(note.created_at, 'D MMM YYYY h:mma') }}
              </p>
            </div>

            <div v-if="!options?.is_sm" class="flex items-center ml-auto">
              <div v-if="checkIsEditable(note)" class="flex ml-auto invisible w-0" :class="{ 'group-hover/note:visible group-hover/note:w-auto': checkIsEditable(note) }">
                <HawkButton icon size="xxs" type="plain" class="mr-2" @click="state.edit_note = { ...note }">
                  <IconHawkEditTwo class="text-gray-500 w-4.5 h-4.5" />
                </HawkButton>
                <HawkButton icon size="xxs" type="plain" :loading="state.is_deleting" @click="onDelete(note)">
                  <IconHawkTrashThree class="text-gray-500 w-4.5 h-4.5" />
                </HawkButton>
              </div>
            </div>
          </div>
          <NotesEditor
            v-if="state.edit_note?.uid === note.uid"
            class="!px-0"
            :note_details="state.edit_note"
            :attachment_config="attachment_config"
            :on_send="on_update"
            @close="state.edit_note = null"
          />
          <template v-else>
            <div v-if="note.is_progress_history">
              <div v-for="(field, i) in (note?.fields_updated || [])" :key="i" class="flex justify-between items-center mb-1">
                <div class="text-xs flex items-center text-gray-600 italic">
                  {{ $t('updated') }}
                  {{ field.field_name }} {{ $t('from') }}
                  <span class="font-semibold mx-1 text-gray-700">{{ field.past }} {{ field.units }}</span>
                  {{ $t('to') }}
                  <span class="font-semibold ml-1 text-gray-700">{{ field.current }} {{ field.units }}</span>
                  <span class="font-semibold ml-1 text-gray-700">(+{{ field.current - field.past }} {{ field.units }})</span>
                </div>
              </div>
            </div>
            <div v-if="note.category" class="flex items-center gap-2 my-2">
              <div class="text-gray-700 font-medium text-xs">
                {{ $t('Category') }}:
              </div>
              <HawkCategoryName :uid="note.category" class="!text-gray-600 !text-xs" />
            </div>
            <div v-if="note.message" class="mt-1">
              <HawkWysiwygEditorComponent
                :editor_enabled="false"
                :initial_content="note.message"
                editor_classes="[&>*]:m-0"
                class="w-full [&>*]:p-0"
              />
            </div>

            <HawkAttachmentsGrid
              v-if="note?.attachments?.length"
              class="mt-3"
              :items="note.attachments.map(item => ({ ...item, thumbnail_small: item.meta?.thumbnails?.small }))"
              :image_dimensions="[120, 120]"
              :grid_gap="3"
              :can_download="!options?.is_sm ?? true"
              :show_delete="!options?.is_sm ?? false"
            />
          </template>
        </div>
      </div>
    </div>
    <NotesEditor
      v-if="show_editor"
      :class="{ 'px-0 py-4': !notes.length }"
      :attachment_config="attachment_config"
      :on_send="on_update"
    />
  </div>
</template>
