<script setup>
import { omit } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import SmConfigForm from '~/system-model/components/forms/sm-config-form.vue';
import SmInstanceForm from '~/system-model/components/forms/sm-instance-form.vue';
import SmActivities from '~/system-model/components/sm-activities.vue';
import SmInstanceGraph from '~/system-model/components/sm-instance-graph.vue';
import SmInventoryItems from '~/system-model/components/sm-inventory-items/sm-inventory-items.vue';
import SmSidebar from '~/system-model/components/sm-sidebar/sm-sidebar.vue';
import SmThermDefects from '~/system-model/components/sm-terra/sm-therm-defects.vue';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import FeatureActivities from '~/terra/components/feature-details/feature-activities.vue';

const { $t } = useCommonImports();
const system_model_store = useSystemModelStore();
const { active_instance, active_instance_details, can_modify_resources } = storeToRefs(system_model_store);

const state = reactive({
  is_loading: false,
  is_fetching: false,
  feature: null,
  defect_search: '',
});

const tabs = computed(() => [
  {
    uid: 'smInstanceDetails',
    label: 'Details',
    disabled: false,
  },
]);

watch(active_instance, async (newValue, oldValue) => {
  if (newValue.uid !== oldValue.uid)
    getData();
});

onMounted(async () => {
  system_model_store.$patch({
    active_instance_hierarchy: [],
    active_tab: 'smInstanceDetails',
  });
  await getData(true);
  const instance_name = active_instance_details.value.name;
  const feature = await system_model_store.get_reference_vectors([instance_name]);
  state.feature = feature?.[instance_name]?.[0] || null;
});
onBeforeUnmount(() => {
  system_model_store.$patch({
    active_instance: null,
    active_instance_details: {},
    active_instance_hierarchy: [],
  });
});

const { open: openDeletePopup, close: closeDeletePopup } = useModal({
  component: HawkDeletePopup,
  attrs: {
    header: $t('Delete instance'),
    content: 'Are you sure you want to delete this instance? This action cannot be undone.',
    onClose() {
      closeDeletePopup();
    },
    confirm: async () => {
      try {
        await system_model_store.delete_instances([{ uid: active_instance.value.uid }]);
        system_model_store.set_active_instance(null);
        system_model_store.set_active_tab('SmInstances');
        closeDeletePopup();
      }
      catch (err) {
        logger.error(err);
      }
    },
  },
});

const instance_modal = useModal({
  component: SmInstanceForm,
});

const config_modal = useModal({
  component: SmConfigForm,
  attrs: {
    onClose() {
      config_modal.close();
    },
  },
});

const instance_context_menu = [
  { label: $t('Config'), on_click: () => config_modal.open() },
  { label: $t('Edit'), on_click: () => openEditModal() },
  { label: $t('Delete'), on_click: () => openDeletePopup() },
];

async function getData(hierarchy = false) {
  if (hierarchy) {
    state.is_fetching = true;
    await system_model_store.set_active_instance_hierarchy({
      instance_id: active_instance.value.uid,
    });
    state.is_fetching = false;
  }
  state.is_loading = true;
  await system_model_store.set_active_instance_details({}, true);
  state.is_loading = false;
}
function openEditModal() {
  instance_modal.patchOptions({
    attrs: {
      onClose() {
        instance_modal.close();
      },
      onClosed() {
        const attrs = omit(instance_modal.options.attrs, ['instance_detail']); // reset attrs
        instance_modal.options.attrs = attrs;
      },
      instance_detail: active_instance_details.value,
    },
  });
  instance_modal.open();
}
</script>

<template>
  <HawkLoader v-if="state.is_fetching" />
  <div v-else class="flex">
    <div class="w-1/6">
      <SmSidebar />
    </div>
    <div class="w-5/6 flex border-t border-gray-200">
      <div id="sm_properties_detail" class="mt-6 mx-4" :class="!state.is_loading ? 'w-1/2' : 'w-full'">
        <div class="flex items-center justify-between">
          <div v-if="active_instance">
            <div class="text-lg font-semibold break-all">
              {{ active_instance_details.name }}
            </div>
            <span class="text-sm">
              {{ system_model_store.templates_components_map[active_instance_details?.component]?.name }}
            </span>
          </div>
          <hawk-menu
            v-if="can_modify_resources"
            class="flex items-center mr-3"
            :items="instance_context_menu"
            additional_trigger_classes="!ring-0"
          >
            <template #trigger>
              <HawkButton type="outlined" size="sm" icon>
                <IconHawkDotsVertical class="text-gray-600 h-5" />
              </HawkButton>
            </template>
          </hawk-menu>
        </div>
        <hawk-tabs :tabs="tabs" class="my-5" active_item="smInstanceDetails" />
        <hawk-loader v-if="state.is_loading" />
        <template v-else>
          <div class="mb-5">
            <SmFieldTable />
            <SmActivities
              v-if="active_instance_details?.uid"
              :active_item_detail="active_instance_details"
            />
            <SmInventoryItems />
            <!-- Defects  -->
            <SmThermDefects />
            <!-- features -->
            <FeatureActivities
              v-if="state.feature"
              class="my-6"
              :feature="state.feature"
              :options="{
                is_sm: true,
                show_editor: false,
              }"
            />
          </div>
        </template>
      </div>
      <div v-if="!state.is_loading" class="w-1/2 flex-auto">
        <SmInstanceGraph />
      </div>
    </div>
  </div>
</template>
