<script setup>
import { computed, ref } from 'vue';
import { findIndex } from 'lodash-es';
import Tree from '~/common/components/organisms/hawk-tree/hawk-tree.vue';

const props = defineProps({
  options: {
    type: Object,
  },
  data: {
    type: Array,
    default: () => [],
  },
  select_type: {
    type: String,
    default: 'ALL',
    /*
      Selected items object with different types of select. Four select types present are -
      1. ALL
      2. BRANCH_PRIORITY
      3. LEAF_PRIORITY
      4. ALL_WITH_INDETERMINATE
    */
  },
  select_view: {
    type: String,
    default: 'TAG',
  },
  // For initial selected properties
  initial_state: {
    type: Array,
    default: () => [],
  },
  flat: {
    type: Boolean,
    default: false,
  },
  children_key: {
    type: String,
    default: 'children',
  },
  label_key: {
    type: String,
    default: 'name',
  },
  data_type: {
    type: String,
    default: 'string',
  },
  value_key: {
    type: String,
    default: 'uid',
  },
  multi_select: {
    type: Boolean,
    default: true,
  },
  disable_branch_nodes: {
    type: Boolean,
    default: false,
  },
  add_classes: {
    type: Object,
    default: () => {},
  },
  get_children_func: {
    type: Function,
    default: null,
  },
  show_tree_select: {
    type: Boolean,
    default: false,
  },
  show_expanded_search: {
    type: Boolean,
    default: false,
  },
  expand_icon_on_right: {
    type: Boolean,
    default: false,
  },
  expand_all_items: {
    type: Boolean,
    default: false,
  },
  // Method to handle select all if flat is sent true cta when clicked needs to return all value keys which needs to be selected given parent value
  handle_select_all: {
    type: Function,
    default: null,
  },
  option_text_length: {
    type: Number,
    default: 50,
  },
});

const emit = defineEmits(['updateForm', 'inputValue']);

const tree_select_data_object = ref({});
const single_selected = ref('');
const search = ref('');
const expanded_rows = ref([]);
const shiftSelectItems = ref([]);
const show_select_all_loader = ref(false);
const show_loader = ref(false);
const available_value_keys_map = ref({});

function updateAvailableValueKeys(data) {
  available_value_keys_map.value[data[props.value_key]] = true;

  (data[props.children_key] || []).forEach((child) => {
    available_value_keys_map.value[child[props.value_key]] = true;
    updateAvailableValueKeys(child);
  });
}

function filterData(parent, search) {
  return parent.map((val) => {
    const data = { ...val };

    if (data[props.label_key].toLowerCase().includes(search)) {
      data.filtered = true;
      updateAvailableValueKeys(data);
      return data;
    }

    if (data[props.children_key] && data[props.children_key].length > 0) {
      data[props.children_key] = filterData(data[props.children_key], search).filter(child => child.filtered);
      if (props.show_expanded_search && !data.filtered && data[props.children_key].length)
        expanded_rows.value.push(data[props.value_key]);

      data.filtered = data[props.children_key].some(child => child.filtered);
      if (data.filtered)
        available_value_keys_map.value[data[props.value_key]] = true;
    }
    else {
      data.filtered = false;
    }

    return data;
  }).filter(val => val.filtered);
}

const get_filtered_data = computed(() => {
  if (search.value)
    return filterData(props.data, search.value.toLowerCase());

  else
    return props.data;
});

// Update Checkbox for parents
function updateParentStatus(val) {
  if (val.parent_id) {
    const parent = tree_select_data_object.value[val.parent_id];
    const sub_children = (parent[props.children_key] || []).map(data => tree_select_data_object.value[data[props.value_key]]);
    let all_unchecked = true;
    let all_checked = true;
    // Checking if children of parent are all checked or all unchecked for calculating parent status
    sub_children.forEach((child) => {
      if (child.status === 'checked') {
        all_unchecked = false;
      }
      else if (child.status === 'unchecked') {
        all_checked = false;
      }
      else {
        // Incase of intermediate status
        all_unchecked = false;
        all_checked = false;
      }
    });
    if (all_checked)
      tree_select_data_object.value[val.parent_id].status = 'checked';
    else if (all_unchecked)
      tree_select_data_object.value[val.parent_id].status = 'unchecked';
    else
      tree_select_data_object.value[val.parent_id].status = 'intermediate';
    // Recursively calling parent of current parent
    updateParentStatus(parent);
  }
}

// Update Checkbox for children
function updateChildStatus(data) {
  const child = (data[props.children_key] || []);
  child.forEach((child) => {
    tree_select_data_object.value[child[props.value_key]].status = data.status;
    // Recursively calling children of current child
    updateChildStatus(tree_select_data_object.value[child[props.value_key]]);
  });
}

// Return Object with Select types
const get_selected_items = computed(() => {
  const all_checked_intermediate = Object.values(tree_select_data_object.value).filter(val => val.status !== 'unchecked');
  const all = all_checked_intermediate.filter(val => val.status === 'checked');
  const branch_priority = all.filter(val => tree_select_data_object.value[val.parent_id]?.status !== 'checked');
  const leaf_priority = all.filter(val => (val[props.children_key] || []).length === 0);
  if (props.flat)
    return {
      [props.select_type]: all,
    };
  return {
    ALL: all,
    BRANCH_PRIORITY: branch_priority,
    LEAF_PRIORITY: leaf_priority,
    ALL_WITH_INDETERMINATE: all_checked_intermediate,
  };
});

// Toggles checkbox
function handleStateChange(uid) {
  if (props.disable_branch_nodes || !props.multi_select) {
    if (single_selected.value === uid) {
      tree_select_data_object.value[single_selected.value].status = 'unchecked';
      single_selected.value = '';
    }
    else {
      if (single_selected.value !== '')
        tree_select_data_object.value[single_selected.value].status = 'unchecked';
      tree_select_data_object.value[uid].status = 'checked';
      single_selected.value = uid;
    }

    return;
  }

  if (tree_select_data_object.value[uid].status === 'checked')
    tree_select_data_object.value[uid].status = 'unchecked';
  else
    tree_select_data_object.value[uid].status = 'checked';
  if (!props.flat) {
  // Updates Parent Checkboxes
    updateParentStatus(tree_select_data_object.value[uid]);
    // Updates Children Checkboxes
    updateChildStatus(tree_select_data_object.value[uid]);
  }
}

// Getting checked items for tags element
const getTagItems = computed(() => {
  return get_selected_items.value[props.select_type].map(data => Object({
    label: data[props.label_key],
    value: data[props.value_key],
    data,
  }));
});

// Function to get children recursively
function get_children(curr, res, parent_id = null, level = 0) {
  // Parent id and level are added
  res.push({ ...curr, parent_id, status: 'unchecked', level });
  if (curr[props.children_key])
    curr[props.children_key].forEach(sub_child => get_children(sub_child, res, curr[props.value_key], level + 1));
}

function init_tree_data(reset = false) {
  props.data.forEach((data) => {
    const res = [];
    get_children(data, res, null, 0);
    // contains flatten array with children and parent and contains some extra properties
    res.forEach((val) => {
      tree_select_data_object.value[val[props.value_key]] = val;
    });
  });
  if (props.expand_all_items)
    expanded_rows.value = Object.values(tree_select_data_object.value).map(data => data[props.value_key]);
  if (reset)
    return;
  // Updating tree select with initial state values
  if (props.data_type === 'object')
    props.initial_state.forEach(data => updateTreeStatus(data[props.value_key]));
  else props.initial_state.forEach(uid => updateTreeStatus(uid));
}
init_tree_data();

watch(getTagItems, (val) => {
  // Update the form when tag items are removed
  if (props.data_type === 'object')
    emit('updateForm', val.map(data => data.data));
  else emit('updateForm', val.map(data => data.value));
  if (!props.multi_select)
    emit('inputValue', single_selected.value === '' ? null : tree_select_data_object.value[single_selected.value]);
}, { immediate: true });

function getLabelValue(val) {
  // enriching if it's just an uid and object is not loaded yet
  if (typeof val === 'string')
    return tree_select_data_object.value[val][props.label_key];
  else
    return val[props.label_key] || val.label;
}

// When removing elements using tags remove button
function treeDeselect(e) {
  if (typeof e === 'string')
    handleStateChange(e);
  else
    handleStateChange(e[props.value_key]);
}

function updateTreeStatus(uid) {
  const data = tree_select_data_object.value[uid];
  if (!data)
    return;
  if (props.flat) {
    handleStateChange(data[props.value_key]);
  }
  else {
    // Branch Priority we take uid directly
    if (props.select_type === 'BRANCH_PRIORITY')
      handleStateChange(data[props.value_key]);
    // For other select types we can consider child nodes and it will give us status according
    if (props.select_type !== 'BRANCH_PRIORITY' && (data[props.children_key] || []).length === 0)
      handleStateChange(data[props.value_key]);
  }
}

function updateStatus(new_val, old_val) {
  if (!(new_val && old_val))
    return;
  if (!new_val.length && !props.multi_select)
    single_selected.value = '';
  if (new_val.length < old_val.length && props.options.search && props.select_view !== 'SELECT')
    old_val.filter(val => !new_val.map(data => data[props.value_key] || data).includes(val[props.value_key] || val)).forEach((val) => {
      const uid = typeof val === 'string' ? val : val[props.value_key];
      if (tree_select_data_object.value[uid].status === 'checked')
        updateTreeStatus(uid);
    });
}

function onSelectItem({ item, shiftKey }) {
  if (!props.multi_select) {
    if (!props.disable_branch_nodes || (props.disable_branch_nodes && !item[props.children_key]))
      handleStateChange(item[props.value_key]);
  }
  else {
    handleStateChange(item[props.value_key]);
  }
  if (shiftKey)
    shiftSelect(item);
}

function shiftSelect(node) {
  if (node === null) {
    shiftSelectItems.value = [];
    return;
  }

  shiftSelectItems.value.push(node);
  if (shiftSelectItems.value.length === 2) {
    const first = tree_select_data_object.value[shiftSelectItems.value[0][props.value_key]];
    const second = tree_select_data_object.value[shiftSelectItems.value[1][props.value_key]];
    let siblings = [];
    if (first.parent_id)
      siblings = tree_select_data_object.value[first.parent_id][props.children_key];

    else
      siblings = props.data;

    const first_index = findIndex(siblings, o => o[props.value_key] === first[props.value_key]);
    const second_index = findIndex(siblings, o => o[props.value_key] === second[props.value_key]);
    siblings.slice(first_index + 1, second_index).forEach(item => onSelectItem({ item, shiftKey: false }));
    shiftSelectItems.value = [];
  }
}
async function handleSelectAll(item) {
  show_select_all_loader.value = item[props.value_key];
  let value_keys = [];
  if (props.handle_select_all)
    value_keys = await props.handle_select_all(item);
  else
    value_keys = [...(item?.[props.children_key] || []).map(child => child[props.value_key]), item[props.value_key]];
  value_keys.forEach((key) => {
    if ((search.value === '' || available_value_keys_map.value[key]) && tree_select_data_object.value[key].status !== 'checked')
      handleStateChange(key);
  });
  show_select_all_loader.value = '';
}
function getAllTagItems() {
  return Object.values(tree_select_data_object.value).map(data => Object({
    label: data[props.label_key],
    value: data[props.value_key],
    data,
  }));
};
function onSearchUpdate() {
  available_value_keys_map.value = {};
  if (search.value === '')
    expanded_rows.value = [];

  show_loader.value = true;
  setTimeout(() => {
    show_loader.value = false;
  }, 500);
}
</script>

<template>
  <div v-if="show_tree_select">
    <HawkSearchInput
      v-if="options.search"
      v-model="search"
      :debounce_time="1500"
      class="mb-2"
      @update:modelValue="onSearchUpdate"
    />
    <HawkLoader v-if="show_loader" />
    <Tree
      v-else-if="get_filtered_data.length"
      style="width: auto; height: 14rem;"
      class="scrollbar"
      :class="add_classes"
      :children_key="children_key"
      :expanded_items_key="value_key"
      :selected_item_key="value_key"
      :value_key="label_key"
      :dynamic_size_key="label_key"
      :expand_icon_on_right="expand_icon_on_right"
      :items="get_filtered_data"
      :expanded_items="expanded_rows"
      :key_field="value_key"
      :get_children_func="get_children_func"
      @selectItem="onSelectItem"
    >
      <template #item="item_data">
        <div class="w-full flex items-center justify-between">
          <vueform v-if="multi_select" size="sm" :class="{ 'ml-4': !item_data.data?.[children_key] }">
            <v-tristate-checkbox
              :key="tree_select_data_object[item_data.data[value_key]].status"
              class="!pointer-events-none"
              :options="{ initial_state: tree_select_data_object[item_data.data[value_key]].status,invisible_checkbox:tree_select_data_object[item_data.data[value_key]].invisible_checkbox }"
            >
              <slot name="multi_select_text" :item="item_data.data">
                <HawkText :length="option_text_length" :content="`${item_data.data[label_key]}` || '-'" />
              </slot>
            </v-tristate-checkbox>
          </vueform>
          <div v-else class="text-[14px]">
            <HawkText :length="option_text_length" :content="item_data.data[label_key]" />
          </div>
          <div
            v-if="tree_select_data_object[item_data.data[value_key]].show_select_all"
            class="group-hover:visible invisible"
            :class="{ '!visible': show_select_all_loader === item_data.data[value_key] }"
            @click.stop="handleSelectAll(tree_select_data_object[item_data.data[value_key]])"
          >
            <slot name="select_all_cta" :data="{ is_loading: show_select_all_loader === item_data.data[value_key] }">
              <HawkLoader v-if="show_select_all_loader === item_data.data[value_key]" container_class="m-0" :width="4" :height="4" />
              <div v-else class="text-sm text-primary-600 cursor-pointer">
                {{ $t('Select all') }}
              </div>
            </slot>
          </div>
        </div>
      </template>
      <template #collapse-icon>
        <IconHawkChevronRight />
      </template>
    </Tree>
    <div v-else-if="search?.length" :class="add_classes">
      <div class="text-sm font-medium text-center text-gray-700 pt-4">
        {{ $t('No results found for') }} {{ search }}
      </div>
    </div>
  </div>
  <SelectElement
    v-else-if="select_view === 'SELECT'"
    v-bind="options"
    :key="multi_select ? `select_elem_${0}` : `select_elem_${single_selected}`"
    :items="getAllTagItems()"
    :add-classes="{
      SelectElement: {
        select: {
          dropdown: '!overflow-y-auto',
          options: '!none !hidden !h-0',
          noOptions: '!none !hidden !h-0',
          noResults: '!none !hidden !h-0',
        },
      },
      ...add_classes,
    }"
    :close-on-select="true"
    @change="updateStatus"
    @deselect="treeDeselect"
    @clear="init_tree_data(true)"
    @searchChange="($event) => {
      if (search !== $event) {
        expanded_rows = [];
        search = $event;
      }
    }"
  >
    <template #before-list>
      <Tree
        v-if="get_filtered_data.length"
        :key="multi_select ? 0 : single_selected"
        style="width: auto; max-height: 14rem; min-height: auto;"
        :children_key="children_key"
        :value_key="label_key"
        :dynamic_size_key="label_key"
        :expand_icon_on_right="expand_icon_on_right"
        :items="get_filtered_data"
        :expanded_items_key="value_key"
        :selected_item_key="value_key"
        :key_field="value_key"
        :get_children_func="get_children_func"
        :expanded_items="expanded_rows"
        :selected_item="tree_select_data_object[single_selected]"
        @selectItem="onSelectItem"
      >
        <template #item="item_data">
          <div v-if="multi_select" :class="{ 'ml-4': !item_data.data?.[children_key] }">
            <HawkCheckbox :key="tree_select_data_object[item_data.data[value_key]]?.status" :model-value="tree_select_data_object[item_data.data[value_key]]?.status" tristate>
              <HawkText :length="option_text_length" :content="item_data.data[label_key]" />
            </HawkCheckbox>
          </div>
          <div v-else class="text-sm">
            <HawkText :length="option_text_length" :content="item_data.data[label_key]" />
          </div>
        </template>
        <template #collapse-icon>
          <IconHawkChevronRight />
        </template>
      </Tree>
      <div v-else class="text-sm p-2">
        No options found
      </div>
    </template>
    <template #single-label="{ value }">
      <slot name="single-label" :value="value">
        <div v-if="!multi_select" class="ml-2 mr-auto">
          <slot name="hawk_tree_select_label" :option="value">
            <HawkText :length="option_text_length" :content="getLabelValue(value)" />
          </slot>
        </div>
        <div v-else class="flex items-center whitespace-nowrap text-sm rounded-lg border ml-2 mr-auto">
          <p class="text-gray-700 w-auto truncate inline-block">
            <HawkText :length="option_text_length" :content="getLabelValue(value)" />
          </p>
        </div>
      </slot>
    </template>
  </SelectElement>
  <TagsElement
    v-else
    v-bind="{
      'can-clear': true,
      'native': false,
      'close-on-select': false,
      ...(options.object ? { valueProp: value_key } : {}),
      ...(options || {}),
    }"
    :key="multi_select ? `tag_elem_${0}` : `tag_elem_${single_selected}`"
    :items="getAllTagItems()"
    :add-classes="{
      TagsElement: {
        select: {
          dropdown: '!overflow-y-auto',
          options: '!none !hidden !h-0',
          noOptions: '!none !hidden !h-0',
          noResults: '!none !hidden !h-0',
        },
      },
      ...add_classes,
    }"
    @change="updateStatus"
    @deselect="treeDeselect"
    @clear="init_tree_data(true)"
    @searchChange="($event) => {
      if (search !== $event) {
        expanded_rows = [];
        search = $event;
      }
    }"
  >
    <template #before-list>
      <Tree
        v-if="get_filtered_data.length"
        :key="multi_select ? 0 : single_selected"
        style="width: auto; height: 14rem;"
        :expanded_items_key="value_key"
        :selected_item_key="value_key"
        :children_key="children_key"
        :value_key="label_key"
        :dynamic_size_key="label_key"
        :expand_icon_on_right="expand_icon_on_right"
        :items="get_filtered_data"
        :key_field="value_key"
        :get_children_func="get_children_func"
        :expanded_items="expanded_rows"
        :selected_item="tree_select_data_object[single_selected]"
        @selectItem="onSelectItem"
      >
        <template #item="item_data">
          <div v-if="multi_select" :class="{ 'ml-4': !item_data.data?.[children_key] }">
            <HawkCheckbox :key="tree_select_data_object[item_data.data[value_key]]?.status" :model-value="tree_select_data_object[item_data.data[value_key]]?.status" tristate>
              <HawkText :length="option_text_length" :content="item_data.data[label_key]" />
            </HawkCheckbox>
          </div>
          <div v-else class="text-sm">
            <HawkText :length="option_text_length" :content="item_data.data[label_key]" />
          </div>
        </template>
        <template #collapse-icon>
          <IconHawkChevronRight />
        </template>
      </Tree>
      <div v-else class="text-sm p-2">
        No options found
      </div>
    </template>
    <template #tag="{ option, handleTagRemove }">
      <div v-if="!multi_select" class="py-0.5 px-1.5 mr-1 mb-1">
        <slot name="hawk_tree_select_label" :option="option">
          <HawkText :length="option_text_length" :content="getLabelValue(option)" />
        </slot>
      </div>
      <div v-else class="flex items-center whitespace-nowrap text-sm rounded-lg border py-0.5 px-1.5 mr-1 mb-1">
        <p class="text-gray-700 w-auto truncate inline-block">
          <HawkText :length="option_text_length" :content="getLabelValue(option)" />
        </p>

        <div class="cursor-pointer ml-1" @mousedown.prevent="handleTagRemove(option, $event)">
          <IconHawkXClose class="text-gray-400 w-4 h-4" />
        </div>
      </div>
    </template>
    <template v-if="$slots.after" #after="{ el$ }">
      <slot name="after" :el="el$" />
    </template>
  </TagsElement>
</template>
