<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import dayjs from 'dayjs';
import { orderBy, round, sum } from 'lodash-es';
import { watch } from 'vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { $date } from '~/common/utils/date.util';
import { import_fusioncharts } from '~/common/utils/package.utils.js';
import { useTerraStore } from '~/terra/store/terra.store.js';
import { safeDivision, safeMultiplication } from '~/terra/utils/terra.utils.js';

const props = defineProps({
  progress_activities: {
    type: Array,
    default: () => ([]),
  },
  options: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['changeWorkflow']);
const button_items = [
  { uid: 'all', label: 'All', tooltip_text: 'All' },
  { uid: '7_days', label: '7 days', tooltip_text: 'Last 7 days' },
  { uid: '30_days', label: '30 days', tooltip_text: 'Last 30 days' },
];
const auth_store = useAuthStore();
if (auth_store?.logged_in_user_details?.timezone)
  dayjs.tz.setDefault(auth_store?.logged_in_user_details?.timezone);
const terra_store = useTerraStore();
const state = reactive({
  show_transition: false,
  workflow: {},
  active_range: 'all',
  workflow_progress: {},
  active_field: null,
  field_activities: [],
  workflow_issue: false,
});

const associated_workflows = computed(() => {
  const workflow_fields = Object.keys(terra_store.selected_features[0].properties.workflowProgress || {});
  const terra_workflows = terra_store.terra_workflows;
  if (!workflow_fields.length)
    return [];
  return Object.keys(terra_workflows).reduce((acc, workflow) => {
    const data_contains_fields = Object.values(terra_workflows[workflow].data).some(data =>
      data.fields.some(field => workflow_fields.includes(field.uid)),
    );
    if (data_contains_fields && !acc.includes(terra_workflows[workflow])) {
      acc.push(terra_workflows[workflow]);
    }
    return acc;
  }, []);
});

function handleTransition() {
  state.show_transition = false;
  setTimeout(() => {
    state.show_transition = true;
  }, 200);
}

function filterDates(data, range) {
  const now = dayjs();

  let startDate;

  if (range === '7_days')
    startDate = now.subtract(7, 'day');
  else if (range === '30_days')
    startDate = now.subtract(30, 'day');
  else
    return data;

  return data.filter(item => dayjs(item.userTimestamp).isAfter(startDate));
}
async function showFieldActivity(field) {
  state.active_field = field;
  state.field_activities = props.progress_activities.filter(activity => activity.field === field.uid);
  await loadActivitiesChart();
}

async function loadActivitiesChart() {
  const { VueFusionChartsComponent, FusionCharts, Charts, FusionTheme } = await import_fusioncharts();
  VueFusionChartsComponent(FusionCharts, Charts, FusionTheme);
  const data = filterDates(state.field_activities, state.active_range)
    .sort((a, b) => {
      return new Date(a?.userTimestamp) - new Date(b?.userTimestamp);
    })
    .map((val) => {
      return {
        value: val.value,

        label: $date(val.userTimestamp, 'MMM DD'),

      };
    });

  new FusionCharts({
    type: 'line',
    renderAt: `line-chart-${state.active_field.uid}`,
    width: '700',
    height: '400',
    dataFormat: 'json',
    dataSource: {
      chart: {
        theme: 'fusion',
        xAxisName: 'Dates',
        yAxisName: state.active_field.units,
        lineThickness: '2',
      },
      data,

    },
  }).render();
}

function init() {
  const feature_details = terra_store.selected_features[0];
  const properties = props.options?.selected_workflow ? { workflow: props.options?.selected_workflow } : feature_details.properties;
  state.workflow = terra_store.workflow_fields_hash(properties);

  if (!state.workflow)
    return;
  const workflow_progress = feature_details.properties.workflowProgress;
  const progress = [];

  for (const field_uid in state.workflow?.fields) {
    const field = state.workflow.fields[field_uid];
    const field_progress = workflow_progress[field_uid];
    if (!field_progress) {
      state.workflow_issue = true;
      break;
    }

    const percentage_completed = safeDivision(field_progress.current, field_progress.total) || 0;

    if (field.type !== 'INTG_101') {
      progress.push({
        ...field,
        ...field_progress,
        percentage_completed,
        percentage_completed_value: round(percentage_completed * 100),
        weightage: state.workflow?.meta?.progress_type === 'cumulative' ? Number(state.workflow?.meta?.weights?.[field_uid]) : safeDivision(1, Object.keys(state.workflow?.fields)?.length),
      });
    }
  }
  state.workflow_progress = progress;
  const percent_and_weightage = [];
  if (!state.workflow_issue) {
    state.workflow_progress.forEach((item) => {
      const field = state.workflow.fields[item.uid];
      if (field?.type !== 'INTG_101')
        percent_and_weightage.push(safeMultiplication(item.percentage_completed, item.weightage));
    });
    state.workflow.percentage_completed = sum(percent_and_weightage);
  }
  handleTransition();
}

function selectRange(item) {
  state.active_range = item.uid;
  loadActivitiesChart();
}
watch(() => props.options?.selected_workflow, () => {
  init();
}, { immediate: true });
</script>

<template>
  <div v-if="state.workflow && !state.workflow_issue" class="my-4">
    <div class="flex justify-between w-full">
      <div class="text-lg font-semibold mb-2 flex-auto">
        {{ $t('Progress') }}
      </div>
      <div v-if="options.is_sm" class="w-56">
        <Vueform
          v-if="associated_workflows?.length > 1"
          size="sm"
          :display-errors="false"
        >
          <SelectElement
            name="workflow"
            :search="true"
            value-prop="uid"
            label-prop="name"
            :native="false"
            :placeholder="$t('Workflow')"
            :can-deselect="false"
            :can-clear="false"
            :items="associated_workflows"
            :default="options?.selected_workflow || terra_store.selected_features?.[0]?.properties?.workflow"
            class="mb-2"
            @select="emit('changeWorkflow', $event)"
          >
            <template #single-label="{ value }">
              <div class="absolute left-0 pl-2 overflow-hidden block whitespace-nowrap max-w-full overflow-ellipsis pr-5 text-gray-900 font-medium">
                <span class="text-sm text-gray-500 font-normal">{{ $t('Workflow') }}:</span>
                {{ value.name }}
              </div>
            </template>
          </SelectElement>
        </Vueform>
        <div v-else class="text-gray-900 font-medium text-end">
          <span class="text-sm text-gray-500 font-normal">{{ $t('Workflow') }}:</span> {{ state.workflow.name }}
        </div>
      </div>
    </div>
    <div v-if="!options.is_sm" class="flex flex-row gap-4 mb-2">
      <div class="text-sm font-medium">
        {{ state.workflow.name }}
      </div>
      <HawkBadge v-if="!state.active_field" color="green">
        {{ round(state.workflow.percentage_completed * 100) }}% {{ $t('complete') }}
      </HawkBadge>
    </div>
    <template v-if="!state.active_field">
      <div class="max-h-[600px] scrollbar">
        <div v-for="field in orderBy(state.workflow_progress, 'field_index')" :key="field.uid">
          <div class="flex items-center flex-row gap-4 p-1">
            <div class="flex-none w-[150px] text-xs font-medium break-all">
              {{ field.name }}
            </div>
            <div class="flex items-center gap-2 h-8 flex-auto">
              <div
                class="h-6 bg-primary-500 rounded-e-lg flex items-center justify-end text-xs text-white p-2 cursor-pointer !min-w-9"
                :class="{ 'pointer-events-none': options?.is_sm }"
                :style="{ width: `${state.show_transition ? field.percentage_completed_value : 0}%`, transition: 'width 500ms ease-in' }"
                @click="showFieldActivity(field)"
              >
                {{ field.percentage_completed_value }}%
              </div>
            </div>

            <div class="text-xs text-gray-700 block">
              {{ field.current }}/{{ field.total }} {{ field.units }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center text-xs mt-2">
        %{{ $t('Progress') }}
      </div>
    </template>

    <template v-if="state.active_field">
      <div class="flex flex-row mb-2 items-center">
        <IconHawkArrowLeft class="w-[14px] h-[14px] mr-2 cursor-pointer" @click="state.active_field = null" />
        <div class="text-sm font-light mr-3">
          {{ state.active_field.name }}
        </div>
        <HawkBadge color="green">
          {{ state.active_field.percentage_completed_value }}% {{ $t('complete') }}
        </HawkBadge>
        <HawkButtonGroup :active_item="state.active_range" class="ml-auto" :items="button_items" size="md" @select="selectRange" />
      </div>
      <div :id="`line-chart-${state.active_field.uid}`" class="w-full h-full" />
    </template>
  </div>
</template>
