<script setup>
const props = defineProps({
  export_type: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['close']);

const $t = inject('$t');
const form$ = ref(null);

const get_popup_details = computed(() => {
  if (props.export_type === 'PDF')
    return { title: $t('Export pdf'), label: $t('Include in PDF') };
  return { title: $t('Export'), label: $t('Export as') };
});

function getPDFOptionItems() {
  return [
    { label: $t('Defect details'), value: 'defect_details', disabled: true, checked: true },
    { label: $t('Raw images'), value: 'raw_images' },
    { label: `${$t('Attachments')} (${$t('manually added')})`, value: 'attachments' },
    { label: $t('Notes'), value: 'notes' },
    { label: `${$t('Tasks')} (${$t('manually added')})`, value: 'tasks_unassociated' },
  ];
}

function getPDFOptions(data) {
  return {
    show_defects: data.pdf_options?.includes('defect_details'),
    show_raw_images: data.pdf_options?.includes('raw_images'),
    show_attachments: data.pdf_options?.includes('attachments'),
    show_notes: data.pdf_options?.includes('notes'),
    show_tasks_unassociated: data.pdf_options?.includes('tasks_unassociated'),
  };
}

function onSave() {
  const data = form$.value.data.options;
  const export_as = data.export_as || props.export_type;
  const pdf_options = getPDFOptions(data);
  emit('export', { export_as, pdf_options });
  emit('close');
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      size="sm"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :endpoint="onSave"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ get_popup_details.title }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <ObjectElement :label="get_popup_details.label" name="options">
            <div class="col-span-12">
              <RadiogroupElement
                v-if="!export_type"
                name="export_as"
                default="CSV"
                :add-classes="{
                  RadiogroupElement: {
                    wrapper: 'gap-y-1',
                  },
                }"
                :items="[
                  'CSV',
                  'PDF',
                ]"
              />
              <div v-if="(export_type || form$?.data?.options?.export_as) === 'PDF'" class="ml-4 mt-1">
                <CheckboxgroupElement
                  name="pdf_options"
                  :default="['defect_details']"
                  :add-classes="{
                    CheckboxgroupElement: {
                      wrapper: 'gap-y-1.5',
                    },
                  }"
                  :items="getPDFOptionItems()"
                />
              </div>
            </div>
          </ObjectElement>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end items-center">
              <ButtonElement
                :button-label="$t('Cancel')"
                class="mr-3"
                :secondary="true"
                @click="emit('close')"
              />
              <ButtonElement
                :button-label="$t('Export')"
                :submits="true"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
