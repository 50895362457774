<script setup>
import { useModal } from 'vue-final-modal';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import AccountSettingsInvitations from '~/acct-settings/components/acct-settings-invitation-popup/acct-settings-invitation-popup.vue';

const { auth_store, $t, route, router, common_store } = useCommonImports();

const page = ref(null);
const state = reactive({
  active_tab: 'users',
  counts: {},
  invitation_update_key: 0,
});

const tabs = computed(() => {
  return [
    {
      uid: 'users',
      label: $t('Users'),
      count: auth_store.check_permission('view_users', route.params.asset_id) ? (state.counts?.users || 0) : '',
      to: { name: 'account-settings-users' },
    },
    {
      uid: 'invites',
      label: $t('Invitations'),
      count: auth_store.check_permission('invite_users', route.params.asset_id) ? (state.counts?.invites || 0) : '',
      to: { name: 'account-settings-invites' },
    },
  ];
});

function openInvitationPopup() {
  const invitation_popup = useModal({
    component: AccountSettingsInvitations,
    attrs: {
      onClose() {
        invitation_popup.close();
      },
      async onUpdate() {
        if (route.params.asset_id)
          await common_store.update_global_data({ assets: true });
        state.invitation_update_key += 1;
      },
    },
  });
  invitation_popup.open();
}

onMounted(() => {
  state.active_tab = tabs.value?.find(item => item.to.name === route.name)?.uid || 'users';
});
function onTabChange(tab) {
  state.active_tab = tab.uid;
}
function setCounts(data) {
  state.counts = {
    ...state.counts,
    ...data,
  };
}
</script>

<template>
  <div>
    <HawkPageHeader class="!px-0">
      <template #title>
        <div>
          <div>{{ $t('Users') }}</div>
          <p class="text-sm font-normal text-gray-600">
            {{ $t('Invite, view and manage users. Assign teams, roles and grant access and permissions.') }}
          </p>
        </div>
      </template>
      <template #right>
        <div class="flex gap-3">
          <HawkButton v-if="auth_store.check_permission('invite_users', route.params.asset_id)" @click="openInvitationPopup">
            <IconHawkPlus class="text-white" />
            <span>{{ $t('Invite members') }}</span>
          </HawkButton>
        </div>
      </template>
    </HawkPageHeader>
    <div>
      <HawkTabs
        :tabs="tabs"
        class="mb-4 mt-2"
        :active_item="state.active_tab"
        @tabClick="onTabChange"
      />

      <router-view :key="state.invitation_update_key" @updateCount="setCounts" />
    </div>
  </div>
</template>
