import dayjs from 'dayjs';
import { isNil, keyBy } from 'lodash-es';
import { markRaw } from 'vue';
import { useThermStore } from '../store/therm.store';
import { useTasksStore } from '~/tasks/store/tasks.store';
import { useCommonStore } from '~/common/stores/common.store';
import { useTagsStore } from '~/common/stores/tags.store';
import { useAuthStore } from '~/auth/stores/auth.store';
import { csvInjectionProtector, sanitizeObjectForCSVInjection } from '~/common/utils/common.utils.js';
import { services } from '~/common/installers/services';

function getDefectDetails(feature, task, feature_types, therm_store, common_store) {
  const properties = {};
  properties['Defect Name'] = `${feature.properties.string_number} ${
    feature_types[feature.properties.featureTypeId]
      ? `: ${feature_types[feature.properties.featureTypeId].name}`
      : ''
  }`;
  properties.Description = task ? task.name : '';
  properties['Defect Type'] = feature_types[feature.properties.featureTypeId]?.name || '';
  properties['Temperature Difference'] = feature.properties.temperature_difference?.toFixed(2);
  if (feature.properties?.adjusted_delta_temperature)
    properties['Adjusted Temperature Difference'] = feature.properties.adjusted_delta_temperature?.toFixed(2);
  properties.String = feature.properties?.string_number;
  if (!therm_store.use_tasks) {
    properties.Status = feature.properties?.status ? therm_store.status_map?.[feature.properties?.status]?.name : '';
    properties.Priority = feature.properties?.priority ? therm_store.priority_map?.[feature.properties?.priority]?.name : '';
    properties.Assignees = feature.properties.assignees
      ? feature.properties.assignees
        .map(id => getUserDetails(id, uid => common_store.get_user(uid)))
        .filter(val => val !== null)
        .join(' | ')
      : '';
    properties.Attachments = `${import.meta.env.VITE_APP_ATTACHMENTS_WEBUI}/${feature.properties.uid}?therm=true`;
  }
  return properties;
}

function getTaskProperties(feature, task, tasks_store, common_store) {
  const tags_store = useTagsStore();
  return {
    Status: task ? tasks_store.status_map[task.status].name : '',
    Priority: task ? tasks_store.priority_map[task.priority].name : '',
    Assignees: task
      ? task.assignees
        .map(id => getUserDetails(id, uid => common_store.get_user(uid)))
        .filter(val => val !== null)
        .join(' | ')
      : '',
    Tags: task
      ? task.tags
        .map(tag => (tags_store.tags_map[tag] ? tags_store.tags_map[tag].name : ''))
        .filter(val => val !== '')
        .join(', ')
      : '',
    Attachments: task
      ? `${import.meta.env.VITE_APP_ATTACHMENTS_WEBUI}/${task.uid}`
      : `${import.meta.env.VITE_APP_ATTACHMENTS_WEBUI}/${feature.properties.uid}?therm=true`,
  };
}

function getLossEstimate(feature, task) {
  const properties = {};
  const yield_loss_estimate = feature.properties?.yield_loss_estimate || task?.properties?.yield_loss_estimate;
  const power_loss_estimate = feature.properties?.power_loss_estimate || task?.properties?.power_loss_estimate;
  if (feature.properties?.description)
    properties['Issue Description'] = feature.properties.description;
  if (feature.properties?.timestamp)
    properties['Timestamp '] = `${dayjs(feature.properties.timestamp.slice(0, 10), 'YYYY:MM:DD').format('DD MMM YYYY')} ${feature.properties.timestamp.slice(10)}`;
  if (yield_loss_estimate)
    properties['Yield Loss Estimate'] = yield_loss_estimate;
  if (power_loss_estimate)
    properties['Power Loss Estimate'] = power_loss_estimate;
  return properties;
}

function getMinMaxTemperature(feature) {
  const properties = {};
  if (!isNil(feature.properties.temperature_min))
    properties['Min Temperature'] = feature.properties.temperature_min?.toFixed(2) ?? '';
  if (!isNil(feature.properties.temperature_max))
    properties['Max Temperature'] = feature.properties.temperature_max?.toFixed(2) ?? '';
  return properties;
}

export async function useOnGenerateCSVClicked(issues, route, isExporting = () => {}) {
  try {
    const therm_store = useThermStore();
    const tasks_store = useTasksStore('therm_tasks_store');
    const $services = markRaw(services);
    const common_store = useCommonStore();
    const auth_store = useAuthStore();
    logger.log(tasks_store);

    const features = issues?.length ? issues : therm_store.features;

    const features_hash = keyBy(features, item => item.properties.uid);
    const filtered_anomalies = [];
    const tasks_uid = [];
    features.forEach((feature) => {
      if (features_hash[feature.properties.uid]) {
        filtered_anomalies.push(features_hash[feature.properties.uid]);
        const task = therm_store.tasks_hash[feature.properties.uid];
        if (task)
          tasks_uid.push(task.uid);
      }
    });
    const { data: comments_data } = await $services.tasks.post({
      attribute: 'comments',
      body: { tasks: tasks_uid },
    });
    const res = filtered_anomalies.map((f) => {
      const task = therm_store.tasks_hash[f.properties.uid];
      return {
        ...getDefectDetails(f, task, therm_store.feature_types, therm_store, common_store),
        ...(therm_store.use_tasks ? getTaskProperties(f, task, tasks_store, common_store) : {}),
        'Timestamp': f?.properties?.timestamp
          ? `${dayjs(f.properties.timestamp.slice(0, 10), 'YYYY:MM:DD').format('DD MMM YYYY')} ${f.properties.timestamp.slice(10)}`
          : '',
        'Inverter':
              therm_store.active_group?.projects[f.properties.projectUid]?.name || '',
        'Scan Date': dayjs(therm_store.active_group?.properties?.date)?.utc()?.format('DD MMM YYYY'),

        ...(f.properties?.serial_number

          ? {
              'Serial Number':
                    f.properties?.serial_number,
            }
          : {}),
        'Comments': task ? comments_data?.data[task.uid]?.map(item => item.text)?.join(' | ') : '',
        'Latitude': f.properties.center[0][0],
        'Longitude': f.properties.center[0][1],
        'Category': task ? (common_store.categories_map[task?.category]?.name || '') : '',
        'Task Uid': task ? task.uid : '',
        'Defect Uid': f.properties.uid,
        ...getLossEstimate(f, task),
        ...getMinMaxTemperature(f),
      };
    });

    const therm_projects = Object.values(therm_store.active_group?.projects || {});
    let filename = `${auth_store.current_organization.name} - ${common_store.assets_map[route.params.asset_id]?.name}`;
    if (therm_store.active_projects?.length === therm_projects.length) {
      filename = `${filename} - All Blocks`;
    }
    else {
      filename = `${filename} - ${therm_projects.filter(project => project.is_active).map(project => project.name).join(', ')}`;
      if (filename.length >= 200)
        //  '- Multiple Blocks' = 18 + 4(.csv) + 5(' (10)')
        // Example: filename.csv, filename (1).csv
        filename = `${filename.slice(0, 180)} - Multiple Blocks`;
    }
    if (!isExporting())
      return;

    const ExcelJS = await import('exceljs');
    const { saveAs } = await import('file-saver');

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Features');

    // how many separate Excel  will open when viewing
    workbook.views = [
      {
        x: 0,
        y: 0,
        width: 10000,
        height: 20000,
        firstSheet: 0,
        activeTab: 1,
        visibility: 'visible',
      },
    ];

    // Add column headers based on the keys in the JSON data
    const headers = [];
    res.forEach((row) => {
      Object.keys(row).forEach((cell) => {
        if (!headers.includes(cell))
          headers.push(csvInjectionProtector(cell));
      });
    });

    worksheet.addRow(headers);

    sanitizeObjectForCSVInjection(res).forEach((item) => {
      const row_values = headers.map(header => item[header]);
      worksheet.addRow(row_values);
    });

    try {
      // for xlsx: const buffer = await workbook.xlsx.writeBuffer();
      const buffer = await workbook.csv.writeBuffer();
      saveAs(new Blob([buffer]), `${filename}.csv`);
    }
    catch (error) {
      logger.log('Error writing excel export', error);
    }

    return Promise.resolve('download complete');
  }
  catch (error) {
    logger.log('csv export error', error);
  }
}

function getUserDetails(uid, getUserById) {
  const user = getUserById(uid);
  if (user)
    return `${user.first_name || ''} ${user.last_name || ''} (${
            user.username
          })`;
  return null;
}
