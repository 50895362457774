import { acceptHMRUpdate, defineStore } from 'pinia';
import { capitalize, filter, keyBy, orderBy, sortBy } from 'lodash-es';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useDashboardStore } from '~/dashboard/store/dashboard.store';
import SelectOptionTemplate from '~/dashboard/components/vueform-schema-templates/select-option-template.vue';

export const useDashboardTerraStore = defineStore('dashboard_terra', {
  state: () => ({
    terra_configuration: {},
    container_options: [],
    workflow_options: [],
    snapshot: null,
    chart_options: [
      ['vertical_graph', 'Vertical Bar Chart'],
      ['horizontal_bar', 'Horizontal Bar Chart'],
      ['donut', 'Pie chart'],
      ['feature_type_table', 'Table'],
    ].map((item) => {
      return {
        value: item[0],
        label: item[1],
      };
    }),
    feature_group_options: [
      ['none', 'None'],
      ['project', 'Layer'],
      ['extraProperties', 'Property'],
    ].map((item) => {
      return {
        value: item[0],
        label: item[1],
      };
    }),
    columns_options: keyBy([
      ['total', 'Total'],
      ['actual', 'Actual'],
      ['remaining', 'Remaining'],
      ['%_actual', '% Actual'],
      ['%_remaining', '% Remaining'],
      ['daily_progress', 'Today'],
      ['this_week', 'This week'],
      ['this_month', 'This month'],
      ['this_year', 'This year'],
    ].map((item) => {
      return {
        value: item[0],
        label: item[1],
      };
    }), 'value'),
    period_options: [
      'daily', 'weekly', 'monthly',
    ].map((item) => {
      return {
        value: item,
        label: capitalize(item),
      };
    }),
    days_options: [
      'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat',
    ].map((item) => {
      return {
        value: item,
        label: capitalize(item),
      };
    }),
    timerange_type_options: [
      ['all', 'All time'],
      ['week', 'Last 7 days'],
      ['bi-week', 'Last 14 days'],
      ['month', 'Last 30 days'],
      ['custom', 'Custom'],
    ].map((item) => {
      return {
        value: item[0],
        label: item[1],
      };
    }),
    projects_options: [],
    properties_options: [],
    feature_types_map: {},
    layers_map: {},
    is_loading_projects: false,
    is_loading_properties: false,
    is_loading_feature_types: false,
    data_set: false,
    data_source_options: [
      ['progress_history', 'Progress history'],
      ['progress_data', 'Progress data'],
      ['feature_properties', 'Feature properties'],
    ].map(item => ({
      value: item[0],
      label: item[1],
    })),
  }),
  getters: {
    terra_widget_type: state => state.terra_configuration?.type || null,
    active_container_id: state => state.terra_configuration?.container || null,
    feature_types_options: state => state.feature_types_map[state.terra_configuration?.container || null] || [],
    layers_options: state => state.layers_map[state.terra_configuration?.container || null] || [],
    selected_projects: state => state.terra_configuration?.projects || [],
    progress_table_options: () => {
      const options = [
        ['date', 'date', 'Date'],
        ['project', 'text', 'Project'],
        ['group', 'text', 'Group'],
        ['field', 'text', 'Field Quantity'],
        ['user', 'text', 'User'],
        ['category', 'text', 'Category'],
      ].map((item) => {
        return {
          key: item[0],
          type: item[1],
          label: item[2],
        };
      });

      return keyBy(options, 'key');
    },
    terra_properties_active: state =>
      state.terra_configuration.type === 'feature_type_counts'
      && state.terra_configuration.chart_type !== 'donut'
      && state.terra_configuration.feature_group === 'extraProperties',
    row_progress_table_options(state) {
      return Object.values(this.progress_table_options || []).filter(o =>
        o.key !== state.terra_configuration.column
        && o.key !== state.terra_configuration.group);
    },
    column_progress_table_options(state) {
      return Object.values(this.progress_table_options || []).filter(o =>
        o.key !== state.terra_configuration.row
        && o.key !== state.terra_configuration.group);
    },
    group_progress_table_options(state) {
      const res = Object.values(this.progress_table_options || []).filter(o =>
        o.key !== state.terra_configuration.row
        && o.key !== state.terra_configuration.column);

      return [
        {
          key: 'none',
          label: 'None',
        },
        ...res,
      ];
    },
    timerange_interval_options: (state) => {
      let options = [];
      if (['all', 'custom'].includes(state.terra_configuration.timerange_type))
        options = ['daily', 'weekly', 'monthly', 'quarterly'];
      else if (state.terra_configuration.timerange_type === 'year')
        options = ['monthly', 'quarterly'];
      else if (state.terra_configuration.timerange_type === 'month')
        options = ['daily', 'weekly'];
      else
        options = ['daily'];

      return options.map(item => ({
        value: item,
        label: capitalize(item),
      }));
    },
    available_columns_options: (state) => {
      const options = state.columns_options || {};

      if (!state.terra_configuration?.sub_columns?.length)
        return Object.values(options);

      const res = Object.values(options);
      res.forEach((option) => {
        option.disabled = state.terra_configuration.sub_columns.includes(option.value);
      });

      return res;
    },
    terra_pivot_table_option() {
      const auth_store = useAuthStore();
      const dashboard_store = useDashboardStore();

      if (auth_store.check_split('pivot_dashboard_widget') && dashboard_store.scope !== 'organization')
        return [['terra_pivot_table', 'Pivot table']];
      else
        return [];
    },
    type_options() {
      return [
        ['feature_type_counts', 'Classes breakdown'],
        ['map-view', 'Map view'],
        ['workprogress_terra_chart', 'Work performed'],
        ['workflow_pivot_table', 'Activity progress table'],
        ...this.terra_pivot_table_option,
      ].map((item) => {
        return {
          value: item[0],
          label: item[1],
          description: item[2],
        };
      });
    },
    terra_schema(state) {
      if (!state.data_set)
        return {};

      return {
        type: {
          type: 'select',
          items: this.type_options,
          search: true,
          native: false,
          label: 'Type',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          rules: [
            'required',
          ],
          default: state.terra_configuration.type || this.type_options[0].value,
          slots: {
            option: SelectOptionTemplate,
          },
        },
        container: {
          type: 'select',
          items: state.container_options,
          search: true,
          native: false,
          label: 'View',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          rules: [
            'required',
          ],
          default: state.terra_configuration.container || state.container_options?.[0]?.value || null,
        },
        data_source: {
          type: 'select',
          items: state.data_source_options,
          search: true,
          native: false,
          label: 'Data Source',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            [
              'type',
              'in',
              [
                'terra_pivot_table',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'terra_pivot_table',
                  ],
                ],
              ],
            },
          ],
          default: state.terra_configuration.data_source || state.data_source_options[0].value,
        },
        workflow: {
          type: 'select',
          items: state.workflow_options,
          search: true,
          native: false,
          label: 'Workflow',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            [
              'type',
              'in',
              [
                'workflow_pivot_table',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'workflow_pivot_table',
                  ],
                ],
              ],
            },
          ],
          default: state.terra_configuration.workflow || null,
        },
        chart_type: {
          type: 'select',
          items: state.chart_options,
          search: true,
          native: false,
          label: 'Chart type',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            [
              'type',
              'in',
              [
                'feature_type_counts',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'feature_type_counts',
                  ],
                ],
              ],
            },
          ],
          default: state.terra_configuration.chart_type || state.chart_options[0].value,
        },
        feature_group: {
          type: 'select',
          items: state.feature_group_options,
          search: true,
          native: false,
          label: 'Group by',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            [
              'type',
              'in',
              [
                'feature_type_counts',
              ],
            ],
            [
              'chart_type',
              'not_in',
              [
                'donut',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'feature_type_counts',
                  ],
                ],
                [
                  'chart_type',
                  'not_in',
                  [
                    'donut',
                  ],
                ],
              ],
            },
          ],
          default: state.terra_configuration.feature_group || state.feature_group_options[0].value,
        },
        feature_properties: {
          type: 'select',
          items: state.properties_options,
          search: true,
          native: false,
          label: 'Properties',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            [
              'type',
              'in',
              [
                'feature_type_counts',
              ],
            ],
            [
              'chart_type',
              'not_in',
              [
                'donut',
              ],
            ],
            [
              'feature_group',
              'in',
              [
                'extraProperties',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'feature_type_counts',
                  ],
                ],
                [
                  'chart_type',
                  'not_in',
                  [
                    'donut',
                  ],
                ],
                [
                  'feature_group',
                  'in',
                  [
                    'extraProperties',
                  ],
                ],
              ],
            },
          ],
          default: state.terra_configuration.feature_properties || state.properties_options?.[0]?.value || null,
        },
        sub_columns: {
          type: 'list',
          label: 'Columns',
          element: {
            type: 'select',
            items: this.available_columns_options,
            search: true,
            native: false,
            canClear: false,
            canDeselect: false,
            inputType: 'search',
            autocomplete: 'off',
          },
          sort: true,
          max: 9,
          conditions: [
            [
              'type',
              'in',
              [
                'workprogress_terra_chart',
              ],
            ],
          ],
          rules: [
            'distinct',
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'workprogress_terra_chart',
                  ],
                ],
              ],
            },
          ],
          default: state.terra_configuration.sub_columns || ['actual', 'total'],
        },
        row: {
          type: 'select',
          items: this.row_progress_table_options,
          search: true,
          native: false,
          label: 'Row',
          canClear: false,
          canDeselect: false,
          object: true,
          inputType: 'search',
          valueProp: 'key',
          autocomplete: 'off',
          conditions: [
            [
              'type',
              'in',
              [
                'workflow_pivot_table',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'workflow_pivot_table',
                  ],
                ],
              ],
            },
          ],
          default: state.terra_configuration.row || {
            key: 'date',
            type: 'date',
            label: 'Date',
          },
        },
        column: {
          type: 'select',
          items: this.column_progress_table_options,
          search: true,
          native: false,
          label: 'Column',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          object: true,
          valueProp: 'key',
          autocomplete: 'off',
          conditions: [
            [
              'type',
              'in',
              [
                'workflow_pivot_table',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'workflow_pivot_table',
                  ],
                ],
              ],
            },
          ],
          default: state.terra_configuration.column || {
            key: 'project',
            type: 'text',
            label: 'Project',
          },
        },
        group: {
          type: 'select',
          items: this.group_progress_table_options,
          search: true,
          native: false,
          label: 'Group',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          object: true,
          valueProp: 'key',
          autocomplete: 'off',
          conditions: [
            [
              'type',
              'in',
              [
                'workflow_pivot_table',
              ],
            ],
          ],
          rules: [
            {
              required: [
                [
                  'type',
                  'in',
                  [
                    'workflow_pivot_table',
                  ],
                ],
              ],
            },
          ],
          default: state.terra_configuration.group || {
            key: 'none',
            label: 'None',
          },
        },
        ...this.timerange_schema,
        prettify_units: {
          type: 'checkbox',
          text: 'Prettify units',
          conditions: [
            [
              'type',
              'in',
              [
                'workprogress_terra_chart',
              ],
            ],
          ],
          default: state.terra_configuration.prettify_units || false,
        },
        transpose: {
          type: 'checkbox',
          text: 'Transpose row and columns',
          conditions: [
            function (form$) {
              if (
                form$.el$('type')?.value === 'feature_type_counts'
                && form$.el$('chart_type')?.value === 'feature_type_table'
              )
                return true;

              return form$.el$('type')?.value === 'workprogress_terra_chart';
            },
          ],
          default: state.terra_configuration.transpose || false,
        },
        module: {
          type: 'hidden',
          default: 'terra',
        },
      };
    },
    timerange_schema(state) {
      return {
        timerange_type: {
          type: 'select',
          items: this.timerange_type_options,
          search: true,
          native: false,
          label: 'Timerange',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            function (form$) {
              return (
                form$.el$('type')?.value === 'workflow_pivot_table'
                && form$.el$('column')?.value?.key === 'date'
              );
            },
          ],
          default: state.terra_configuration.timerange_type || this.timerange_type_options?.[0]?.value || null,
        },
        timerange_range: {
          type: 'date-time',
          label: 'Range',
          options: {
            range: true,
            format: 'dd MMM yyyy',
          },
          autocomplete: 'off',
          conditions: [
            function (form$) {
              return (
                form$.el$('type')?.value === 'workflow_pivot_table'
                && form$.el$('column')?.value?.key === 'date'
                && form$.el$('timerange_type')?.value === 'custom'
              );
            },
          ],
          default: state.terra_configuration.timerange_range || [],
        },
        timerange_interval: {
          type: 'select',
          items: this.timerange_interval_options,
          search: true,
          native: false,
          label: 'Interval',
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          conditions: [
            function (form$) {
              return (
                form$.el$('type')?.value === 'workflow_pivot_table'
                && form$.el$('column')?.value?.key === 'date'
              );
            },
          ],
          default: state.terra_configuration.timerange_interval || this.timerange_interval_options?.[0]?.value || null,
        },
      };
    },
    asset_id() {
      const dashboard_store = useDashboardStore();

      return dashboard_store.widget_asset;
    },
  },
  actions: {
    update_terra_configuration(data) {
      this.terra_configuration = { ...data };
    },
    async set_containers() {
      const { data } = await this.$services.terra_view_service.getAll({
        query: {
          asset: this.asset_id,
        },
      });

      this.container_options = sortBy(data?.map((c) => {
        return {
          value: c.uid,
          label: c.name,
        };
      }) || [], ['label']);
    },
    async set_workflows() {
      const { data } = await this.$services.terra_workflow_service.getAll({
        query: {
          asset: this.asset_id,
        },
      });

      this.workflow_options = data.map((wf) => {
        return {
          ...wf,
          value: wf.uid,
          label: wf.name,
        };
      });
    },
    async set_container_projects() {
      this.projects_options = [];
      if (!this.terra_configuration.container)
        return;

      this.is_loading_projects = true;

      const { data } = await this.$services.terra_view_service.get({
        id: this.terra_configuration.container,
      });

      this.projects_options = [
        ...orderBy(
          filter(data.groups, p => p.properties?.date),
          [p => p.properties.date],
          ['desc'],
        ),
        ...orderBy(
          filter(data.groups, p => !p.properties?.date),
          [p => p.name],
          ['asc'],
        ),
      ];
      this.is_loading_projects = false;
    },
    async set_properties() {
      this.properties_options = [];

      this.is_loading_properties = true;

      const { data } = await this.$services.features.get_feature_properties({
        body: {
          projects: this.terra_configuration?.projects,
        },
      });

      const options = Object.keys(data || {}).map(key => ({
        value: key,
        label: key,
      }));
      this.properties_options = options;
      this.is_loading_properties = false;
    },
    async set_container_feature_types() {
      const container_uid = this.terra_configuration.container;
      if (!container_uid || this.feature_types_map[container_uid])
        return;

      this.is_loading_feature_types = true;

      const { data } = await this.$services.feature_type_groups.getAll({
        query: {
          pk: true,
          container: container_uid,
        },
      });

      const feature_types = orderBy(data.filter(group => group.featureTypes?.length > 0), ['name']);

      feature_types.forEach((group) => {
        const ftgs = orderBy(group.featureTypes, ['name']);
        ftgs.forEach((ftg) => {
          ftg.name = `${group.name} - ${ftg.name}`;
        });
        group.featureTypes = [...ftgs];
      });

      this.feature_types_map[container_uid] = [...feature_types];
      this.is_loading_feature_types = false;
    },
    set_terra_map_data(payload) {
      const cpy = { ...this.terra_configuration };
      cpy.map_view_data = payload;
      this.terra_configuration = cpy;
    },
    widget_min_w(widget_type) {
      return widget_type === 'feature_type_counts' ? 4 : 8;
    },
    parse_terra_form_to_server_format(widget_data) {
      if (!Object.keys(widget_data || {}).length)
        return null;

      const authStore = useAuthStore();
      const dashboard_store = useDashboardStore();

      const payload = {
        properties: {
          source: {
            type: 'terra',
            service: 'terra',
          },
          data: {
            container_uid: widget_data.container,
          },
          config: widget_data.chart_config || {},
        },
        filters: {
          organization: authStore.current_organization?.uid,
          asset_uid: widget_data.asset_id,
        },
        chart: widget_data.type,
        minW: this.widget_min_w(widget_data.type),
      };

      if (widget_data.type === 'feature_type_counts' && widget_data.chart_type === 'feature_type_table') {
        payload.filters.project = widget_data.projects;

        payload.filters.container = widget_data.container;

        if (widget_data.feature_group !== 'none')
          payload.agg = {
            key: 'count',
            label: 'count',
          };

        if (widget_data.feature_group === 'project')
          payload.group = {
            key: 'project',
            type: 'project',
            label: 'project',
          };

        else if (widget_data.feature_group === 'extraProperties')
          payload.group = {
            key: widget_data.feature_properties,
            type: widget_data.feature_group,
            label: widget_data.feature_properties,
          };

        else
          payload.group = null;

        payload.chart = 'feature_type_table';

        if (widget_data.feature_types?.length)
          payload.filters.featureType = widget_data.feature_types;

        payload.properties.transpose = widget_data.transpose;
        payload.properties.columns_widths = widget_data.columns_widths;
      }
      else if (widget_data.type === 'feature_type_counts' && widget_data.chart_type !== 'feature_type_table') {
        payload.filters.project = widget_data.projects;
        payload.filters.container = widget_data.container;

        payload.x = {
          key: 'feature-type',
          label: (() => {
            if (widget_data.feature_group === 'none')
              return 'Class';
            else if (widget_data.feature_group === 'project')
              return 'Layer';
            else
              return widget_data.feature_properties;
          })(),
          type: 'short_text',
          extract: false,
        };

        payload.y = {
          key: 'jTyKzZJaJ',
          label: 'Count of features',
          type: 'dropdown',
          extract: false,
          agg: 'count',
        };

        if (widget_data.feature_group !== 'none' && widget_data.chart_type !== 'donut')
          payload.agg = {
            key: 'count',
            label: 'count',
          };

        if (widget_data.feature_group === 'project' && widget_data.chart_type !== 'donut')
          payload.group = {
            key: 'project',
            type: 'project',
            label: 'project',
          };

        else if (widget_data.feature_group === 'extraProperties' && widget_data.chart_type !== 'donut')
          payload.group = {
            key: widget_data.feature_properties,
            type: widget_data.feature_group,
            label: widget_data.feature_properties,
          };

        else
          payload.group = null;

        payload.chart = widget_data.chart_type;

        if (widget_data.feature_types?.length)
          payload.filters.featureType = widget_data.feature_types;
      }
      else if (widget_data.type === 'workprogress_terra_chart') {
        const columns = widget_data.workprogress_fields || [];

        const formatted_columns = columns?.map(field => ({
          ...field,
          column_types: widget_data.sub_columns,
        }));

        payload.properties.transpose = widget_data.transpose;
        payload.properties.prettify_units = widget_data.prettify_units;
        payload.data = {};
        payload.data = {
          columns: formatted_columns,
          projects: widget_data.projects,
        };
        payload.properties.fields = columns;

        payload.filters.project = widget_data.projects;
        payload.properties.columns_widths = widget_data.columns_widths;
      }
      else if (widget_data.type === 'workflow_pivot_table') {
        payload.filters.container = widget_data.container;
        payload.filters.workflow = widget_data.workflow;
        payload.x = widget_data.row;
        payload.y = widget_data.column;

        if (payload?.y?.key === 'date') {
          payload.y.timerange = {};
          payload.y.timerange.type = widget_data.timerange_type;
          payload.y.timerange.range = null;
          payload.y.timerange.interval = widget_data.timerange_interval;
        }

        if (payload?.y?.key === 'date' && widget_data.timerange_type === 'custom') {
          payload.y.timerange.range = {};
          payload.y.timerange.range = {
            from: (new Date(widget_data.timerange_range[0] || null)).toISOString(),
            to: (new Date(widget_data.timerange_range[1] || null)).toISOString(),
          };
        }

        if (![null, 'none'].includes(widget_data.group?.key))
          payload.group = widget_data.group;

        payload.agg = {};
        payload.agg = {
          key: 'sum',
          label: 'sum',
        };

        payload.properties.columns_widths = widget_data.columns_widths;
      }
      else if (widget_data.type === 'map-view') {
        payload.filters.container = widget_data.container;
        payload.properties.map_view_data = widget_data.map_view_data;
        payload.properties.data = {};
        payload.properties.data.container_uid = widget_data.container;
        payload.properties.data.snapshot = widget_data.map_view_data.snapshot;
        payload.properties.data.legend = widget_data.map_view_data.legend;
        payload.properties.data.timestamp = widget_data.map_view_data.timestamp;
        payload.filters.project = widget_data.projects || [];
        payload.filters.featureType = widget_data.feature_types || [];
      }
      else if (widget_data.type === 'terra_pivot_table') {
        payload.properties.data_source = widget_data.data_source;
        payload.properties.projects = widget_data.projects;

        const pivot_config = dashboard_store.pivot_table_instance?.getReport({
          withGlobals: true,
        });
        pivot_config.active_chart = dashboard_store.pivot_table_instance?.active_chart;
        if (pivot_config)
          delete pivot_config.dataSource;

        widget_data.properties = { ...(widget_data?.properties || {}), pivot_config };

        payload.properties.pivot_config = pivot_config;
      }
      if (payload?.x?.timerange)
        delete payload.x.timerange;

      return payload;
    },
    set_workflow_table_initial_data(widget_data) {
      const widget_columns = widget_data.properties?.fields
      || widget_data.properties?.activity_table_fields
      || widget_data.data?.columns
      || [];

      const workprogress_fields = widget_columns?.map((c) => {
        return {
          ...c,
          column: c.column || c.uid,
          name: c.name || 'Untitled',
        };
      }) || [];

      const properties = [];

      for (const [parent_key, values] of Object.entries(widget_data?.filters?.extraProperties || {}))
        values.forEach((val) => {
          properties.push(`${parent_key}__${val}`);
        });

      return {
        workprogress_fields,
        sub_columns: widget_data.data?.columns?.[0]?.column_types || [],
        transpose: widget_data.properties?.transpose || false,
        prettify_units: widget_data.properties?.prettify_units || false,
        projects: widget_data.filters?.project || widget_data.properties?.project || [],
        properties,
      };
    },
    set_map_view_initial_data(widget_data) {
      return {
        map_view_data: {
          snapshot: widget_data.properties?.data?.snapshot || null,
          legend: widget_data.properties?.data?.legend || null,
          timestamp: widget_data.properties?.data?.timestamp || null,
        },
      };
    },
    set_feature_types_initial_data(widget_data) {
      return {
        feature_types: widget_data.filters?.featureType || [],
        workflow: widget_data.filters?.workflow || null,
        chart_config: widget_data.properties?.config || null,
        feature_group: widget_data?.group?.type || 'none',
        feature_properties: widget_data?.group?.type === 'extraProperties' ? widget_data?.group?.key : null,
      };
    },
    set_pivot_table_initial_data(widget_data) {
      return {
        row: widget_data?.x || {
          key: 'date',
          type: 'date',
          label: 'Date',
        },
        column: widget_data?.y || {
          key: 'project',
          type: 'text',
          label: 'Project',
        },
        group: widget_data?.group || {
          key: 'none',
          label: 'None',
        },
        timerange_type: widget_data.y?.timerange?.type || 'all',
        timerange_range: Object.values(widget_data.y?.timerange?.range || {}) || [],
        timerange_interval: widget_data.y?.timerange?.interval || 'daily',
      };
    },
    unparse_terra_form_to_server_format(widget_data) {
      if (!Object.keys(widget_data || {}).length)
        return null;

      const widget_types = this.type_options.map(o => o.value);

      let type = null;
      let chart_type = null;
      if (widget_types.includes(widget_data.chart)) {
        type = widget_data.chart;
      }
      else {
        chart_type = widget_data.chart;
        type = 'feature_type_counts';
      }

      return {
        type,
        chart_type,
        name: widget_data.properties?.name || 'Untitled',
        container: widget_data.properties?.data?.container_uid || null,
        columns_widths: widget_data.properties?.columns_widths || {},
        asset_id: widget_data.filters?.asset_uid || null,
        module: 'terra',
        ...this.set_workflow_table_initial_data(widget_data),
        ...this.set_pivot_table_initial_data(widget_data),
        ...this.set_feature_types_initial_data(widget_data),
        ...this.set_map_view_initial_data(widget_data),
        ...(widget_data.chart === 'terra_pivot_table'
          ? {
              data_source: widget_data.properties.data_source,
              projects: widget_data.properties.projects,
              pivot_config: widget_data.properties.pivot_config,
            }
          : {}),
      };
    },
    set_data() {
      const dashboard_store = useDashboardStore();
      const widget_configuration = dashboard_store.widget_configuration;

      if (
        !Object.keys(widget_configuration || {}).length
        || widget_configuration?.module !== 'terra'
      ) {
        this.data_set = true;
        this.terra_configuration = {
          ...this.terra_configuration,
          asset_id: dashboard_store.widget_asset,
        };
        return;
      }

      this.terra_configuration = { ...widget_configuration };

      this.data_set = true;
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDashboardTerraStore, import.meta.hot));
