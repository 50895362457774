<script setup>
import { computed, onMounted, reactive } from 'vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { $t, route, router } = useCommonImports();

const state = reactive({
  active_tab: '',
});
const tabs = computed(() => {
  return [
    {
      uid: 'account-settings-categories',
      label: $t('Categories'),
    },
    {
      uid: 'account-settings-tags',
      label: $t('Tags'),
    },
  ];
});
function onTabChange(tab) {
  state.active_tab = tab.uid;
  router.push({
    name: tab.uid,
  });
}

onMounted(() => {
  state.active_tab = router.currentRoute?.value?.name;
});
</script>

<template>
  <div>
    <HawkTabs
      v-if="!route?.params?.asset_id"
      :tabs="tabs"
      class="pt-4 pb-2 flex"
      :active_item="state.active_tab"
      @tabClick="onTabChange"
    />
    <router-view />
  </div>
</template>
