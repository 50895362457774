<script setup>
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { useRoute } from 'vue-router';
import { useTasksStore } from '~/tasks/store/tasks.store';
import { useThermHelperComposable } from '~/therm/composables/helper-composable';
import { useThermStore } from '~/therm/store/therm.store';

const emit = defineEmits(['close']);

const route = useRoute();
const tasks_store = useTasksStore('therm_tasks_store');
const therm_store = useThermStore();
const { addSymbols } = useThermHelperComposable();
const form$ = ref(null);
const default_data = {
  filter: {
    associated_with_defects: false,
    not_associated_with_defects: false,
    show_defects: true,
  },
  include_feature_types: ['all'],
  temperature_difference: [0, 100],
};

function formatLoadedData(data) {
  const load_data = {};

  Object.keys(data).forEach((key) => {
    if (['due_date_start', 'due_date_end'].includes(key)) {
      const date_key = key.replace(/_(start|end)$/, '');
      load_data[date_key] = load_data[date_key] || [];
      const dateArray = load_data[date_key];
      const dateValue = new Date(data[key]);
      key.endsWith('start') ? dateArray.unshift(dateValue) : dateArray.push(dateValue);
    }
    else {
      load_data[key] = data[key];
    }
  });
  if (Object.keys(load_data).length)
    return load_data;
  return default_data;
}
function generatePayload(data) {
  const payload = {};
  const handleAssignees = (key) => {
    const assignees = data[key];
    if (assignees?.length)
      payload[key] = assignees.map(assignee => assignee.uid);
  };
  const handleDate = (key, startKey, endKey) => {
    const dateArray = data[key];
    if (dateArray?.length > 0) {
      payload[startKey] = dayjs(dateArray[0]).toISOString();
      payload[endKey] = dayjs(dateArray[1]).toISOString();
    }
  };
  Object.keys(data).forEach((key) => {
    switch (key) {
      case 'assignees':
      case 'owner':
        handleAssignees(key);
        break;
      case 'due_date':
        handleDate('due_date', 'due_date_start', 'due_date_end');
        break;
      default:
        if (key !== 'undefined' && data[key] && `${data[key]}`.length)
          payload[key] = data[key];
    }
  });
  if (isEqual(payload, default_data))
    return null;
  return payload;
}
function defectOptions() {
  return [
    { name: 'All defects', id: 'all' },
    ...Object.values(therm_store.feature_types).map(val => ({ id: val.id, name: val.name })),
  ];
}
function clearFilters() {
  form$.value.reset();
}
async function onSave(formData, { data }) {
  therm_store.selected_features = [];
  const filters = generatePayload(data);
  therm_store.set_filters({ ...filters });
  const features = therm_store.features;
  therm_store.update_map_features({
    features,
    set_polygon: true,
  });
  if (therm_store.defect_table_instance)
    therm_store.defect_table_instance.resetPagination();
  if (therm_store.map)
    addSymbols();
  emit('close');
}

async function handleMounted() {
  await form$.value?.load?.(therm_store.filters, true);
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      size="sm"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      :format-load="formatLoadedData"
      :endpoint="onSave"
      @mounted="handleMounted"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ $t('filters') }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <!-- Filter -->
          <ObjectElement
            class="mb-4"
            name="filter"
            :label="$t('Filter tasks')"
          >
            <CheckboxElement name="show_defects" :default="true" disabled>
              {{ $t('Defects') }}
            </CheckboxElement>
            <CheckboxElement name="associated_with_defects">
              {{ $t('Tasks') }} ({{ $t('associated') }})
            </CheckboxElement>
            <CheckboxElement name="not_associated_with_defects">
              {{ $t('Tasks') }} ({{ $t('unassociated') }})
            </CheckboxElement>
          </ObjectElement>

          <StaticElement
            :add-classes="{ ElementLabel: { wrapper: '!text-base font-semibold ' } }"
            class="mb-6"
            :label="$t('Defect filters')"
          />

          <!-- Defects -->
          <TagsElement
            class="mb-6"
            name="include_feature_types"
            label-prop="name"
            value-prop="id"
            track-by="name"
            :label="$t('Defects')"
            :placeholder="$t('Select')"
            :items="defectOptions()"
            :default="['all']"
            :search="true"
            :close-on-select="false"
          />

          <!-- Defect Status -->
          <TagsElement
            class="mb-6"
            name="defect_status"
            label-prop="name"
            value-prop="uid"
            track-by="name"
            :label="$t('Defect status')"
            :placeholder="$t('Select Status')"
            :items="therm_store?.status_configs"
            :search="true"
            :close-on-select="false"
          />

          <!-- Defect Priority -->
          <TagsElement
            class="mb-6"
            name="defect_priority"
            label-prop="name"
            value-prop="uid"
            track-by="name"
            :label="$t('Defect priority')"
            :placeholder="$t('Select Priority')"
            :items="therm_store?.priority_configs"
            :search="true"
            :close-on-select="false"
          />

          <!-- Temperature difference -->
          <SliderElement
            class="mb-10"
            name="temperature_difference"
            tooltip-position="bottom"
            :label="$t('Temperature difference')"
            :default="[0, 100]"
            :merge="-1"
          />

          <StaticElement
            :add-classes="{ ElementLabel: { wrapper: '!text-base font-semibold ' } }"
            class="mb-6"
            :label="$t('Task filters')"
            :conditions="[[
              ['filter.associated_with_defects', true],
              ['filter.not_associated_with_defects', true],
            ]]"
          />

          <!-- Task Status -->
          <v-tags-input
            :options="{
              name: 'status',
              label: $t('Task status'),
              placeholder: $t('Select Status'),
              items: tasks_store?.status_values,
              search: true,
              closeOnSelect: false,
              tags_removable: true,
              labelProp: 'label',
              dataProp: 'value',
              conditions: [[
                ['filter.associated_with_defects', true],
                ['filter.not_associated_with_defects', true],
              ]],
            }"
            class="mb-6"
          />

          <!-- Task Priority -->
          <v-tags-input
            :options="{
              name: 'priority',
              label: $t('Task priority'),
              placeholder: $t('Select Priority'),
              items: tasks_store?.priority_values,
              search: true,
              closeOnSelect: false,
              tags_removable: true,
              labelProp: 'label',
              dataProp: 'value',
              conditions: [[
                ['filter.associated_with_defects', true],
                ['filter.not_associated_with_defects', true],
              ]],
            }"
            class="mb-6"
          />

          <!-- Assignees -->
          <hawk-assignee-input
            class="mb-6"
            :options="{
              name: 'assignees',
              has_teams: true,
              label: $t('Assignees'),
              placeholder: $t('Select Assignees'),
            }"
            :asset_id="route.params.asset_id"
            :multi="true"
            :format_load="true"
            :conditions="[[
              ['filter.associated_with_defects', true],
              ['filter.not_associated_with_defects', true],
            ]]"
          />

          <!-- Due Date -->
          <DateTimeElement
            class="mb-6"
            name="due_date"
            :label="$t('Due Date')"
            :options="{
              range: true,
              format: 'dd/MM/yyyy',
              placeholder: $t('Select Due Date'),
              startTime: [{ hours: 0, minutes: 0 }, { hours: 23, minutes: 59, seconds: 59 }],
            }"
            :conditions="[[
              ['filter.associated_with_defects', true],
              ['filter.not_associated_with_defects', true],
            ]]"
          />

          <!-- Category -->
          <hawk-category-input
            class="mb-6"
            :options="{
              name: 'category',
              label: $t('Category'),
              placeholder: $t('Select Category'),
              tags_removable: true,
            }"
            :multi="true"
            :asset_id="route.params.asset_id"
            :conditions="[[
              ['filter.associated_with_defects', true],
              ['filter.not_associated_with_defects', true],
            ]]"
          />

          <!-- Created By -->
          <hawk-assignee-input
            :options="{
              label: $t('Created By'),
              name: 'owner',
              placeholder: $t('Select Owner'),
            }"
            :multi="true"
            :format_load="true"
            :conditions="[[
              ['filter.associated_with_defects', true],
              ['filter.not_associated_with_defects', true],
            ]]"
          />
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #left>
            <hawk-button type="text" @click="clearFilters">
              {{ $t('Clear filters') }}
            </hawk-button>
          </template>
          <template #right>
            <div class="flex justify-end items-center">
              <ButtonElement
                :button-label="$t('Cancel')"
                class="mr-3"
                :secondary="true"
                @click="emit('close')"
              />
              <ButtonElement
                :button-label="$t('Apply')"
                :submits="true"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
