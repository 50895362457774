<script setup>
import { useRoute, useRouter } from 'vue-router';
import { jwtDecode } from 'jwt-decode';
import { inject, onMounted } from 'vue';
import dayjs from 'dayjs';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import { clearAuthentication } from '~/common/utils/common.utils';

const route = useRoute();
const router = useRouter();
const is_loading = ref(false);
const auth_store = useAuthStore();
const common_store = useCommonStore();
const errors_state = reactive({
  user: '',
  first_name: '',
  last_name: '',
  password: '',
  error: '',
});
const email$ = ref(null);
const $services = inject('$services');
const form = ref({});
const decoded_token = ref(null);
const $toast = inject('$toast');
const toggle_password_type = ref(false);
const toggle_confirm_password_type = ref(false);
const term_condition_unchecked = ref(true);
const rules_dropdown_active = ref(false);
const in_progress = ref(false);
const password_rules = ref([
  {
    type: 'length',
    description: 'Atleast 8 characters',
    fulfilled: false,
    condition: (password = '') => password.length >= 8,
  },
  {
    type: 'capitalize',
    description: 'Contain 1 lower case and 1 upper case letter',
    fulfilled: false,
    condition: (password) => {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).*$/;
      return passwordRegex.test(password);
    },
  },
  {
    type: 'alpha_numeric',
    description: 'Contain a number and a special character',
    fulfilled: false,
    condition: (password) => {
      const passwordRegex = /^(?=.*\d)(?=.*[^a-zA-Z\d\s]).*$/;
      return passwordRegex.test(password);
    },
  },
]);

const fulfilled_rules = computed(() => {
  return password_rules.value.filter(rule => rule.fulfilled);
});

const unfulfilled_rules = computed(() => {
  return password_rules.value.filter(rule => !rule.fulfilled);
});

onMounted(() => {
  clearAuthentication();
  if (route.query.p) {
    decoded_token.value = jwtDecode(route.query.p);
    form.value.email = decoded_token.value.email;
    email$.value.load(decoded_token.value.email);
  }
});

async function onSignUpClicked() {
  try {
    if (route.query.p && decoded_token.value.email) {
      is_loading.value = true;
      await $services.auth.confirmEnrollment({
        query: { p: route.query.p },
        body: form.value,
      });
      $toast({
        title: 'Registered, please login to continue.',
        type: 'success',
      });
      is_loading.value = false;
      router.push({ name: 'sign-in' });
    }
    else {
      is_loading.value = true;
      const signup_response = await $services.auth.signUp({
        body: { ...form.value, timezone: dayjs.tz.guess(true) },
        ...(route.query.p && {
          query: { p: route.query.p },
        }),
      });
      auth_store.sign_up_details.formData = form.value;
      auth_store.sign_up_details.uid = signup_response.data.uid;

      router.push({ path: '/auth/verify-email' });
      is_loading.value = false;
    }
  }
  catch (error) {
    is_loading.value = false;
    if (error?.data?.code === 'CORE_50')
      errors_state.user = error.data.message;
    else if (error?.data?.code === 'CORE_60')
      errors_state.password = error.data.message;
    else
      errors_state.error = error.data.message || 'Internal error';
    logger.log(error);
  }
}

async function onSignUpWithGoogleClicked() {
  await auth_store.socialSignIn('sign-up');
}

function handlePasswordRulesCheck() {
  in_progress.value = true;
  const password = form.value.password;
  password_rules.value.forEach(rule => rule.fulfilled = rule.condition(password));
  if (password.length === 0)
    in_progress.value = false;
}

function getProgressWidth() {
  const fulfilled_rules_length = fulfilled_rules.value.length || 1;
  return fulfilled_rules_length === 3 ? '100%' : `${fulfilled_rules_length * 30}%`;
}

function getPasswordSecureStatusColor() {
  const fulfilled_rules_length = fulfilled_rules.value.length;
  switch (fulfilled_rules_length) {
    case 1:
      return '#F95E68';
    case 2:
      return '#FDD244';
    case 3:
      return '#35CC62';
    default:
      return '#F95E68';
  }
}
</script>

<template>
  <div class="min-h-screen">
    <div class="sm:mx-auto sm:w-full sm:max-w-md pt-24">
      <div class="flex justify-center mb-6">
        <HawkIconTaskmapperIcon />
      </div>
      <p class="text-center text-3xl font-semibold tracking-tight text-gray-900 mb-3">
        {{ $t('Welcome to TaskMapper') }}
      </p>
      <span class="text-gray-600">
        {{ $t('A Unified Platform for Solar Projects') }}
      </span>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow-[0_2px_16px_8px_rgba(112,117,126,0.08)] rounded-xl sm:px-10">
        <Vueform
          v-model="form"
          :display-errors="false"
          :columns="{
            sm: { container: 12, label: 12, wrapper: 12 },
          }"
          size="sm"
          @submit="onSignUpClicked"
        >
          <div class="col-span-12 grid gap-4">
            <TextElement
              ref="email$"
              :disabled="decoded_token && decoded_token.email"
              name="email"
              label="Email"
              input-type="email"
              :placeholder="$t('Enter email')"
              autocomplete="off"
              rules="required|email"
              :add-class="{
                inputContainer: '!h-10',
              }"

              @input="errors_state.user = ''"
            >
              <template #after>
                <div v-if="errors_state.user" class="text-red-600 text-left">
                  {{ errors_state.user }}
                </div>
                <div v-else-if="errors_state.error" class="text-red-600 text-left">
                  {{ errors_state.error }}
                </div>
              </template>
            </TextElement>
            <template v-if="!decoded_token?.email">
              <TextElement
                name="first_name"
                :label="$t('First name')"
                :placeholder="$t('Enter first name')"
                autocomplete="off"
                rules="required"
                :add-class="{
                  inputContainer: '!h-10',
                }"
                @input="errors_state.first_name = ''"
              />
              <TextElement
                name="last_name"
                :label="$t('Last name')"
                :placeholder="$t('Enter last name')"
                autocomplete="off"
                rules="required"
                :add-class="{
                  inputContainer: '!h-10',
                }"
                @input="errors_state.last_name = ''"
              />
            </template>
            <TextElement
              name="password"
              label="Password"
              :rules="['required', 'confirmed']"
              class="mt-1 relative"
              autocomplete="off"
              :placeholder="$t('Enter password')"
              :add-class="{
                inputContainer: '!h-10',
              }"
              :messages="{ regex: 'Password should contain minimum 1 Uppercase, 1 Lowercase, 1 number and 1 special character' }"
              :input-type="toggle_password_type ? 'text' : 'password'"
              @click=" rules_dropdown_active = true;" @change="handlePasswordRulesCheck"
              @blur="rules_dropdown_active = false"
              @input="errors_state.password = ''"
            >
              <template #addon-after>
                <div class="cursor-pointer w-8 h-8 grid place-items-center" @click.stop="toggle_password_type = !toggle_password_type">
                  <IconHawkEye v-if="toggle_password_type" class="h-4 w-4" />
                  <IconHawkEyeOff v-else class="h-4 w-4" />
                </div>
              </template>
              <template #description>
                <div v-if="rules_dropdown_active && fulfilled_rules.length < password_rules.length" class="p-[14px] border border-gray-200 z-20 absolute bg-white w-full shadow-lg rounded-md flex flex-col gap-3">
                  <div v-for="rule in password_rules" :key="rule.description" class="flex items-center">
                    <span class="w-2 h-2 rounded-full border border-gray-300 mr-[9px]" :style="{ backgroundColor: rule.fulfilled ? 'green' : 'red' }" />
                    <p class="text-gray-700 font-medium text-sm" :style="{ color: rule.fulfilled ? 'green' : 'black' }">
                      {{ rule.description }}
                    </p>
                  </div>
                </div>
                <div v-if=" in_progress && !rules_dropdown_active && fulfilled_rules.length >= 0" class="w-full mt-2">
                  <div class="progress_bar_container rounded-md h-[8px] w-full border border-gray-200 overflow-hidden">
                    <div class="progress_bar h-full" :style="{ width: getProgressWidth(), backgroundColor: getPasswordSecureStatusColor() }" />
                  </div>
                  <p class="text-gray-700 text-sm text-left">
                    Your password is <span class="font-semibold">{{ fulfilled_rules.length > password_rules.length - 1 ? 'secure' : 'weak' }}</span>
                  </p>
                  <p v-if="unfulfilled_rules.length" class="text-red-600 text-left">
                    {{ unfulfilled_rules[unfulfilled_rules.length - 1].description }}
                  </p>
                </div>
              </template>
              <template #after>
                <div v-if="errors_state.password" class="text-red-600 text-left">
                  {{ errors_state.password }}
                </div>
              </template>
            </TextElement>
            <TextElement
              name="password_confirmation"
              label="Confirm Password"
              rules="required"
              class="mt-1"
              :placeholder="$t('Enter password again')"
              :add-class="{
                inputContainer: '!h-10',
              }"
              autocomplete="off"
              :input-type="toggle_confirm_password_type ? 'text' : 'password'"
            >
              <template #addon-after>
                <div class="cursor-pointer w-8 h-8 grid place-items-center" @click.stop="toggle_confirm_password_type = !toggle_confirm_password_type">
                  <IconHawkEye v-if="toggle_confirm_password_type" class="h-4 w-4" />
                  <IconHawkEyeOff v-else class="h-4 w-4" />
                </div>
              </template>
            </TextElement>
            <CheckboxElement
              name="checkbox"
              class="py-2"
              rules="required"
              @change="term_condition_unchecked = !term_condition_unchecked"
            >
              <div class="font-medium">
                {{ $t('I agree with the') }} <a href="https://sensehawk.com/privacy-policy" target="_blank" class="text-blue-700 hover:underline">{{ $t('Terms and conditions') }}</a>
              </div>
            </CheckboxElement>
            <ButtonElement
              name="submit"
              button-label="Sign up"
              :submits="true"
              :loading="is_loading"
              button-class="w-full bg-blue-600 !py-[9px] !text-sm"
            />
            <div v-if="!common_store.is_ril" class="w-full col-span-12 mx-auto">
              <div class="relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="w-full border-t border-gray-200" />
                </div>
                <div class="relative flex justify-center text-sm font-medium leading-6">
                  <span class="bg-white px-2 text-gray-500">Or</span>
                </div>
              </div>
            </div>
            <ButtonElement
              v-if="!common_store.is_ril"
              name="with google" size="md" button-class="w-full !text-base" :override-classes="{
                ButtonElement: {
                  button: '!bg-white',
                  button_primary: '!text-gray-700 !font-semibold !border !border-gray-300',
                  button_enabled: 'transition-none',
                },
              }"
              @click="onSignUpWithGoogleClicked"
            >
              <span class="flex justify-center items-center">
                <HawkIconGoogle height="24" width="24" class="mr-3 " />
                <span class="text-sm">{{ $t('Sign up with Google') }}</span>
              </span>
            </ButtonElement>
          </div>
        </Vueform>
        <div class="mt-4 flex justify-center text-gray-600 text-sm">
          {{ $t("Already have an account") }}?
          <div class="mx-2 cursor-pointer" @click="$router.push({ name: 'sign-in' })">
            <div class="text-blue-600 hover:text-blue-500 font-semibold">
              {{ $t("Sign in") }}
            </div>
          </div>
        </div>
      </div>
      <div class="py-12 text-gray-600 text-sm w-96 mx-auto">
        <div>
          {{ $t('By logging in to TaskMapper, you accept Sensehawk’s') }}
          <a href="https://sensehawk.com/" class="underline">Terms of Service</a>,
          <a href="https://sensehawk.com/" class="underline">Privacy Policy</a>
          and <a href="https://sensehawk.com/" class="underline"> Cookie Policy</a>.
        </div>
        <div class="my-3">
          © Sensehawk, Inc.
        </div>
      </div>
    </div>
  </div>
</template>
