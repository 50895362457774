<script setup>
const props = defineProps({
  date_options: {
    type: Array,
    default: () => ([]),
  },
});

const $t = inject('$t');

const BEFORE_AFTER_DROPDOWN_OPTIONS = [
  { value: 'after', label: $t('After') },
  { value: 'before', label: $t('Before') },
];

const date_dropdown_options = computed(() => {
  if (props.date_options.length)
    return props.date_options;

  return [{ value: 'dueDate', label: $t('Due date') }];
});
</script>

<template>
  <div class="grid grid-rows-1 grid-flow-col gap-4">
    <TextElement
      :rules="['required', 'numeric', 'min:1']"
      default="1"
      name="days"
      size="sm"
      type="number"
      :attrs="{ min: '1' }"
      :addons="{ after: `<span class='text-sm'>${$t('days')}</span>` }"
      input-type="number"
      :columns="{
        default: { container: 7 },
        sm: { container: 7 },
        md: { container: 7 },
      }"
    />
    <SelectElement
      default="before"
      placeholder="Select"
      :rules="['required']"
      value="when"
      size="sm"
      :columns="{
        default: { container: 7 },
        sm: { container: 7 },
        md: { container: 7 },
      }"
      :can-clear="false"
      name="when"
      :native="false"
      :items="BEFORE_AFTER_DROPDOWN_OPTIONS"
    />
    <SelectElement
      :rules="['required']"
      :placeholder="$t('Due date')"
      size="sm"
      value="from"
      :default="date_dropdown_options[0].value"
      :columns="{
        default: { container: 8 },
        sm: { container: 8 },
        md: { container: 8 },
      }"
      :can-deselect="false"
      :can-clear="false"
      :disabled="date_dropdown_options.length === 1"
      :close-on-select="true"
      name="from"
      :native="false"
      :items="date_dropdown_options"
    />
    <TextElement
      min="1"
      default="1"
      size="sm"
      :rules="['required', 'numeric', 'min:1']"
      :addons="{ after: `<span class='text-sm'> ${$t('times')}</span>` }"
      name="count"
      type="number"
      :attrs="{ min: '1' }"
      input-type="number"
      :columns="{
        default: { container: 9 },
        sm: { container: 9 },
        md: { container: 9 },
      }"
    />
  </div>
</template>
