<script setup>
import { capitalize, keyBy, max, uniq } from 'lodash-es';
import { permissionsMatrix } from '~/acct-settings/components/acct-permissions/permissions-matrix.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { $services, route, $t } = useCommonImports();

const permissions_matrix = permissionsMatrix($t);
const COLUMNS = [
  { label: 'Basic', key: 'basic', max: 0 },
  { label: 'Template Manager', key: 'templates_manager', max: 1 },
  { label: 'Limited', key: 'limited', max: 2 },
  { label: 'Advanced', key: 'advanced', max: 3 },
  { label: 'Manager', key: 'manager', max: 4 },
  { label: 'Admin', key: 'admin', max: 5 },
];

const state = reactive({
  is_loading: false,
  assets_summary: [],
  permissions_summary: {},
  permissions_metadata: {},
  permissions: [],
});

async function getData() {
  try {
    state.is_loading = true;

    const { data } = await $services.permissions_v2.permissions_summary({
      id: route?.params?.id,
      ...(route.params.asset_id ? { asset_id: route?.params?.asset_id } : {}),
    });

    state.assets_summary = data.assets;
    for (const [key] of Object.entries(state.permissions_metadata)) {
      state.permissions_summary[key] = data.permissions[key];
    }

    const column_key = keyBy(COLUMNS, 'key');
    for (const key in state.permissions_summary) {
      const value = state.permissions_summary[key];
      state.permissions_metadata[key].max = max(uniq(value.map(val => column_key[val.permission.toLowerCase()]?.max)));
    }

    state.is_loading = false;
  }
  catch (error) {
    state.is_loading = false;
    logger.log('🚀 ~ getData ~ error:', error);
  }
}

function columnData() {
  const get_status = (column_data, permission, index, max) => {
    // show hyphen - No permissions
    if (!column_data)
      return 0;
    // show cross - permissions, no set
    else if (!permission.length)
      return 1;
    // show grey tick - permissions, set
    else if (max === index)
      return 3;
    // show green tick - permissions, highest value
    return 2;
  };

  for (const permission_key in state.permissions_summary) {
    const _permission = {
      module: permission_key,
      columns: COLUMNS.map((column, index) => {
        const column_data = permissions_matrix.find(pm => pm.value === permission_key)?.columns.find(c => c.value === column.key);
        const permission_value = state.permissions_summary[permission_key].filter(p => p?.permission?.toLowerCase() === column.key) || [];
        return {
          status: get_status(column_data, permission_value, index, state.permissions_metadata[permission_key]?.max),
          value: permission_value,
          header: column.key,
        };
      }),
    };
    state.permissions.push(_permission);
  }
}

function getTooltipContent(value) {
  let text = '';
  text = value.map((val) => {
    if (val.type === 'direct')
      return '<span class="text-xs font-semibold">Direct user permission</span>';
    return `<span class="text-xs font-semibold">${capitalize(val.resource_type)}: ${capitalize(val.resource_name)}</span>`;
  });
  return text.join('<br/>');
}

onMounted(async () => {
  await permissions_matrix.forEach((item) => {
    state.permissions_metadata[item.value] = { key: item.value };
  });
  await getData();
  columnData();
});
</script>

<template>
  <div class="mb-5 text-sm font-semibold text-gray-900">
    {{ $t('Permissions summary') }}
  </div>

  <HawkLoader v-if="state.is_loading" />
  <div v-else class="grid gap-5">
    <div v-if="state.assets_summary?.length">
      <table class="w-full">
        <thead class="bg-gray-50">
          <tr>
            <th class="w-3/12">
              {{ $t('Assets') }}
            </th>
            <th class="w-9/12">
              {{ $t('Maps & Layers') }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="asset in state.assets_summary" :key="asset.asset">
            <td>
              <HawkAssetName :uid="asset.asset" />
            </td>
            <td>
              <span v-if="asset.resource_access === 'full'">{{ $t('Full Access') }}</span>
              <span v-else-if="asset.resource_access === 'no'">{{ $t('No Access') }}</span>
              <template v-else-if="asset.resource_access === 'limited'">
                <span v-for="(resource, index) in asset.asset_resources" :key="resource.id">
                  {{ resource.name }}
                  <span v-if="index !== asset.asset_resources.length - 1">, </span>
                </span>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <table class="table-fixed w-full">
        <thead class="bg-gray-50">
          <tr>
            <th class="w-3/12">
              {{ $t('Applications') }}
            </th>
            <th v-for="(column, index) in COLUMNS" :key="column.key" class="!text-center !border-l-0" :class="{ '!border-r-0': index !== COLUMNS.length - 1 }">
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="permission in state.permissions" :key="permission.module">
            <td class="capitalize">
              {{ permission.module }}
            </td>
            <td v-for="(column, index) in permission.columns" :key="column" class="!border-l-0" :class="{ '!border-r-0': index !== permission.columns.length - 1 }">
              <span v-if="column?.status === 0" class="flex justify-center">
                -
              </span>
              <div
                v-else-if="column?.status === 1"
                class="flex justify-center opacity-30"
              >
                <IconHawkXTwo />
              </div>
              <div
                v-else-if="column?.status === 2"
                v-tippy="{ content: getTooltipContent(column.value), placement: 'bottom', allowHTML: true }"
                class="flex justify-center"
              >
                <IconHawkCheckThree />
              </div>
              <div
                v-else-if="column?.status === 3"
                v-tippy="{ content: getTooltipContent(column.value), placement: 'bottom', allowHTML: true }"
                class="flex justify-center"
              >
                <IconHawkCheckFour />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
table {
  border-collapse: collapse;
}

th,td {
  @apply border text-left;
}

th{
  @apply text-xs font-medium py-2 px-3 text-gray-600;
}
td{
  @apply text-sm font-medium py-2 px-3;
}
</style>
