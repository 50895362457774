<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useModal } from 'vue-final-modal';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store.js';
import { searchData, sortRowsByColumn } from '~/common/utils/common.utils';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import DuplicateTeam from '~/acct-settings/components/acct-settings-duplicate-team.vue';

const $t = inject('$t');
const $toast = inject('$toast');
const auth_store = useAuthStore();
const common_store = useCommonStore();
const route = useRoute();
const router = useRouter();

const state = reactive({
  search: '',
});
const columns = [
  {
    header: $t('Name'),
    accessorKey: 'uid',
    id: 'uid',
    cell: info => info.getValue(),
    columns: [],
    sortingFn: sortRowsByColumn,
  },
  {
    header: $t('Created on'),
    accessorKey: 'created_at',
    id: 'created_at',
    cell: info => info.getValue(),
    columns: [],
  },
  {
    header: $t('Members'),
    accessorKey: 'users',
    id: 'users',
    cell: info => info.getValue(),
    columns: [],
  },
  {
    header: $t('Roles'),
    accessorKey: 'roles',
    id: 'roles',
    cell: info => info.getValue(),
    columns: [],
  },
  {
    accessorKey: 'context_menu',
    header: '',
    id: 'context_menu',
    size: '5',
    show_on_hover: 'true',
  },
];

const hawk_menu_items = [
  {
    label: $t('Duplicate'),
    uid: 'duplicate',
    on_click: () => {
      emit('actionClick', 'duplicate');
    },
  },
  {
    label: $t('Edit'),
    uid: 'edit',
    on_click: () => {
      emit('actionClick', 'edit');
    },
  },
  {
    label: $t('Delete'),
    uid: 'delete',
    on_click: () => {
      emit('actionClick', 'delete');
    },
  },
];

const filtered_data = computed(() => searchData(common_store?.scope_teams(route?.params?.asset_id), state.search));

const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
});

const { open: openDuplicateModal, close: closeDuplicateModal, patchOptions: patchTeamsOptions } = useModal({
  component: DuplicateTeam,
});

function teamDuplicateHandler(team) {
  patchTeamsOptions(
    {
      attrs: {
        item: team,
        onClose() {
          closeDuplicateModal();
        },
      },
    },
  );
  openDuplicateModal();
}

function teamDeleteHandler({ name, uid }) {
  patchOptions(
    {
      attrs: {
        header: $t('Delete Team'),
        content: `Are you sure you want to delete ${name || ''}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            await common_store.update_data({
              type: 'delete',
              id: uid,
              service: 'teams',
              append_data: false,
              state_prop: 'teams_map',
              update_state: true,
            });
            $toast({
              title: $t('Team deleted'),
              text: $t('The team has been successfully deleted'),
              type: 'success',
            });
            closeDeletePopup();
          }
          catch (err) {
            $toast({
              title: $t('Failed to delete team'),
              text: $t('Unable to delete the team. Please try again'),
              type: 'error',
            });
          }
        },
      },
    },
  );
  openDeletePopup();
}

async function onActionClicked(type, team) {
  try {
    switch (type) {
      case 'duplicate':
        teamDuplicateHandler(team);
        break;
      case 'edit':
        router.push({
          name: 'account-settings-team-details',
          params: {
            id: team.uid,
          },
        });
        break;
      case 'delete':
        teamDeleteHandler(team);
        break;
    }
  }
  catch (error) {
    logger.error('error', error);
  }
}

function teamUserData(users) {
  return [
    ...common_store.filter_users(users),
    ...common_store.filter_teams(users),
  ];
}
</script>

<template>
  <HawkPageHeader class="!px-0">
    <template #title>
      <div>
        <div>{{ $t('Teams') }}</div>
        <p class="text-sm text-gray-600 font-normal">
          {{ route?.params?.asset_id
            ? $t('Organize and manage groups of users to streamline access, collaboration and operations within your organization.')
            : $t('Organize and manage groups of users to streamline access, collaboration and operations within the asset.') }}
        </p>
      </div>
    </template>
    <template #right>
      <HawkButton v-if="auth_store.check_permission('create_teams', route.params.asset_id)" color="primary" @click="router.push({ name: 'account-settings-team-details' })">
        <IconHawkPlus class="text-white" />
        <span>{{ $t('New Team') }}</span>
      </HawkButton>
    </template>
  </HawkPageHeader>
  <div class="my-4">
    <div v-if="!auth_store.check_permission('view_teams', route.params.asset_id)">
      <HawkIllustrations type="no-permission" for="teams" />
    </div>
    <div v-else-if="!filtered_data?.length">
      <HawkIllustrations v-if="state.search" type="no-results" for="teams" />
      <HawkIllustrations v-else type="no-data" for="teams" />
    </div>
    <div v-else-if="filtered_data?.length">
      <TableWrapperVue container_class="!mt-0 border">
        <HawkTable
          :pagination_config="{ totalRows: filtered_data?.length, pageSize: 25 }"
          :data="filtered_data"
          :columns="columns"
          :default_height="500"
          is_gapless
          :non_sortable_columns="['context_menu']"
          @rowClicked="e => router.push({
            name: 'account-settings-team-details',
            params: {
              id: e.uid,
            },
          })"
        >
          <template #uid="uid">
            <div>
              <div class="flex items-center gap-2">
                <HawkMembers
                  :members="uid.data.getValue()" type="label"
                  :has_avatar="false"
                  name_classes="font-medium text-gray-900"
                  :name_truncate_length="100"
                />

                <hawk-badge v-if="auth_store.check_split('default_teams') && uid?.data?.row?.original?.default" color="orange">
                  {{ $t('Default') }}
                </hawk-badge>
              </div>
              <div class="text-xs text-gray-600">
                {{ uid.data.row.original?.description }}
              </div>
            </div>
          </template>
          <template #roles="roles">
            <div v-if="roles.data.getValue()?.length" class="flex gap-2 flex-wrap">
              <div v-for="role in roles.data.getValue()" :key="role">
                <HawkBadge v-if="common_store.roles_map[role]?.uid" color="blue">
                  <HawkRoleName :uid="common_store.roles_map[role]?.uid" class="text-xs" />
                </HawkBadge>
              </div>
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #created_at="created_at">
            {{ $date(created_at.data.getValue(), 'L_DATE_MED') }}
          </template>
          <template #users="users">
            <HawkMembers
              :members="teamUserData(users.data.getValue())"
              type="badge"
              popover_avatar_size="badge"
              is_fixed
            />
          </template>
          <template #context_menu="team">
            <HawkMenu
              v-if="auth_store.check_permission('modify_teams', route.params.asset_id)"
              :items="hawk_menu_items"
              position="fixed"
              class="z-[999]"
              @click.stop
            >
              <template #trigger>
                <IconHawkDotsVertical />
              </template>
              <template #content>
                <div class="p-1 w-48 flex flex-col">
                  <HawkButton
                    v-for="item in hawk_menu_items"
                    :key="item.label" type="text"
                    @click="onActionClicked(item.uid, team.data.row.original)"
                  >
                    {{ item.label }}
                  </HawkButton>
                </div>
              </template>
            </HawkMenu>
          </template>
        </HawkTable>
      </TableWrapperVue>
    </div>
  </div>
</template>
