<script setup>
import { reactive } from 'vue';

const props = defineProps({
  table_options: {
    type: Object,
  },
  on_import: {
    type: Function,
  },
});

const $toast = inject('$toast');

const state = reactive({
  data: [],
  added_columns: [],
  deleted_columns: [],
});

const form = ref({ file: null });

function createData() {
  const file_data = form.value.file;
  const reader = new FileReader();
  reader.onload = async function (event) {
    try {
      const lines = event.target.result.replace(/\r/g, '').split(/\n/);

      const old_columns = props.table_options.columns.slice(2).map(val => val.data);
      let new_columns = lines[0].split(',');

      const required_columns = ['uid', 'name', 'Feature type', 'description'];
      const missing_required_columns = required_columns.filter(val => !new_columns.includes(val));
      if (missing_required_columns.length) {
        $toast({
          title: 'Error',
          text: `Some required columns are missing (${missing_required_columns.join(', ')}). Please check your file and try again.`,
          type: 'error',
          position: 'top-right',
        });
        state.data = [];
      }
      else {
        state.data = lines.slice(1).map((line) => {
          const values = line.split(',');
          return new_columns.reduce((row_Object, col, index) => {
            if (col.length)
              row_Object[col] = values[index];
            return row_Object;
          }, {});
        });
        new_columns = new_columns.filter(col => !!col.length);
        // added and deleted columns
        state.deleted_columns = old_columns.filter(column => !new_columns.includes(column));
        state.added_columns = new_columns.filter(column => !old_columns.includes(column));
      }
    }
    catch (error) {
      $toast({
        title: 'Error',
        text: 'Please check your file something went wrong.',
        type: 'error',
        position: 'top-right',
      });
      logger.error(error);
    }
  };
  if (file_data) {
    reader.readAsText(file_data);
  }
  else {
    state.data = [];
    state.deleted_columns = [];
    state.added_columns = [];
  }
}

async function onImport() {
  await props.on_import(state.data);
}
</script>

<template>
  <hawk-modal-container :width="600" content_class="rounded-lg max-w-[600px]">
    <Vueform
      v-model="form" sync size="sm" :display-errors="false"
      :columns="{
        lg: {
          container: 12,
          label: 4,
          wrapper: 12,
        },
      }"
      :endpoint="onImport"
    >
      <div class="col-span-12">
        <hawk-modal-header class="!px-6 !py-4" @close="$emit('close')">
          <template #title>
            <div class="flex items-center">
              {{ $t('Import') }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <FileElement
            name="file"
            class="mb-5"
            :label="$t('Choose file')"
            :presets="['hawk_file_element']"
            :options="{
              clickable_text: $t('Upload'),
              text: 'CSV file',
              description: '.csv format supported',
            }"
            rules="required"
            accept=".csv"
            :auto="false"
            :drop="true"
            @change="createData"
          />
          <div class="col-span-12">
            <div v-if="state.added_columns.length" class="grid grid-cols-12 mb-3">
              <div class="col-span-4 text-sm text-gray-900 font-medium">
                {{ $t('Added Columns') }}
              </div>
              <div class="col-span-8">
                <div class="flex items-center flex-wrap">
                  <HawkBadge v-for="col in state.added_columns" :key="col" color="green" class="mr-2 mb-2">
                    {{ col }}
                  </HawkBadge>
                </div>
              </div>
            </div>
            <div v-if="state.deleted_columns.length" class="grid grid-cols-12 mb-3">
              <div class="col-span-4 text-sm text-gray-900 font-medium">
                {{ $t('Deleted Columns') }}
              </div>
              <div class="col-span-8">
                <div class="flex items-center flex-wrap">
                  <HawkBadge v-for="col in state.deleted_columns" :key="col" color="red" class="mr-2 mb-2">
                    {{ col }}
                  </HawkBadge>
                </div>
              </div>
            </div>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer class="flex justify-between items-center">
          <template #right>
            <div class="flex justify-end items-center">
              <ButtonElement
                :button-label="$t('Cancel')"
                class="mr-3"
                :secondary="true"
                @click="$emit('close')"
              />
              <ButtonElement
                :button-label="$t('Import')"
                :disabled="!state.data.length"
                :submits="true"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
