import dayjs from 'dayjs';
import { chunk, compact, groupBy, keyBy, pickBy } from 'lodash-es';
import { computed, markRaw, ref } from 'vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { usePdfExporter } from '~/common/composables/pdf-exporter.js';
import { services } from '~/common/installers/services';
import { useCommonStore } from '~/common/stores/common.store.js';
import { htmlToText, sortObjectByKey } from '~/common/utils/common.utils.js';
import { $date } from '~/common/utils/date.util';
import { useTasksStore } from '~/tasks/store/tasks.store';
import { useThermStore } from '~/therm/store/therm.store';

export function useThermPdfExporter(updatePdfExportProgress = () => {}, isExporting =
() => {}, options = {}) {
  const common_store = useCommonStore();
  const therm_store = useThermStore();
  const auth_store = useAuthStore();

  const pdf_exporter = usePdfExporter();

  const task_comments_map = ref({});
  const total_files_to_export = ref(0);
  const current_export_file_index = ref(0);

  const class_ids = computed(() => {
    return Object.values(therm_store.feature_types).reduce((acc, cur) => {
      acc[cur.id] = cur.name;
      return acc;
    }, {});
  });
  const active_group_name = computed(() => {
    if (therm_store.active_group) {
      return (
        (therm_store.active_group?.properties?.date
          && $date(therm_store.active_group.properties.date, 'D-M-YYYY'))
          || therm_store.active_group.name
      );
    }

    else {
      return '';
    }
  });
  const block_names_list = computed(() => {
    return therm_store.active_projects
      .map(project => project.name)
      .sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
      .join(', ');
  });
  const projects = computed(() => {
    return therm_store.active_group.projects;
  });

  const current_organization = auth_store.current_organization;
  const current_organization_name = current_organization.name; // when no args are passed, it returns current_organization?.name
  const current_asset_name = common_store.active_asset.name;

  const date_now_local = (new Date()).toLocaleString();
  const key_styles = { fontSize: 10, margin: [0, 10], colSpan: 2 };
  const value_styles = { bold: true, fontSize: 10, margin: [0, 10], colSpan: 5 };
  const priority_codes = {
    1: { name: 'Critical', color: '#fc5a5a' },
    2: { name: 'High', color: '#ffaf3b' },
    3: { name: 'Medium', color: '#08aa4f' },
    4: { name: 'Low', color: '#747891' },
  };
  const status_codes = {
    1: { name: 'Pending', color: '#f79c4b' },
    2: { name: 'In Progress', color: '#08aa4f' },
    3: { name: 'Resolved', color: '#5240b9' },
    4: { name: 'Closed', color: '#b9bbc7' },
    5: { name: 'Rejected', color: '#f85859' },
  };

  function generateValueColumns(column_text, options = {}) {
    const value_columns = [];
    value_columns.push({
      text: column_text,
      ...value_styles,
      ...options,
    });
    for (let columns = 1; columns < value_styles.colSpan; columns++)
      value_columns.push({});

    return value_columns;
  }
  function generateKeyColumns(column_text, options = {}) {
    const key_columns = [];
    key_columns.push({ text: column_text, ...key_styles, ...options });
    for (let columns = 1; columns < key_styles.colSpan; columns++)
      key_columns.push({});

    return key_columns;
  }
  function createKeyCell(text) {
    return {
      text,
      style: 'bold_10',
      borderColor: ['#ededed', '#ededed', '#ededed', '#ededed'],
    };
  }
  function createValueCell(text) {
    return {
      text,
      style: 'simple_10',
      borderColor: ['#ededed', '#ededed', '#ededed', '#ededed'],
    };
  }
  function createCover({ current_index, total_pdf_count }) {
    return [
      {
        text: '',
        alignment: 'center',
        margin: [0, 50, 0, 0],
      },
      {
        text: 'Thermography Report',
        style: 'h1',
        alignment: 'center',
        margin: [0, 30, 0, 12],
      },
      total_pdf_count > 1
        ? {
            text: `Part ${current_index} of ${total_pdf_count}`,
            style: 'h4',
            alignment: 'center',
            margin: [0, 0, 0, 100],
          }
        : { text: '', margin: [0, 0, 0, 100] },
      {
        text: current_asset_name,
        style: 'h2',
        alignment: 'center',
        margin: [0, 0, 0, 5],
      },
      {
        text: current_organization_name,
        style: 'h4',
        alignment: 'center',
        bold: true,
        margin: [0, 0, 0, 100],
      },
      {
        text: [
          'Scan: ',
          {
            text: active_group_name.value,
            style: 'h4',
            alignment: 'center',
            bold: false,
          },
        ],
        style: 'h4',
        alignment: 'center',
        bold: true,
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          'Blocks: ',
          {
            text: block_names_list.value,
            style: 'h4',
            alignment: 'center',
            bold: false,
          },
        ],
        style: 'h4',
        alignment: 'center',
        bold: true,
        margin: [0, 0, 0, 5],
        pageBreak: 'after',
      },
    ];
  }
  function createSummaryTable(table_data) {
    const { class_names, group_anomalies } = table_data;

    // Split class_names into chunks of 7
    const chunks = [];
    for (let i = 0; i < class_names.length; i += 7)
      chunks.push(class_names.slice(i, i + 7));

    let contents = chunks.map(chunk => ({
      table: {
        widths: ['*', ...Array.from({ length: chunk.length + 1 }).fill('auto')],
        body: [
          [
            { text: 'Inverter Blocks', style: 'table_header' },
            ...chunk.map(text => ({
              text,
              style: 'table_header',
            })),
            { text: 'Grand Total', style: 'table_header' },
          ],
        ],
      },
      layout: 'nice_table',
    }));

    const counts_by_type = {};
    let grand_total = 0;
    for (const project in group_anomalies) {
      let total = 0;
      chunks.forEach((chunk, index) => {
        const row = [project];
        for (const class_name of chunk) {
          if (!group_anomalies[project]?.[class_name]) {
            row.push('0');
          }
          else {
            row.push(group_anomalies[project][class_name]);
            total += group_anomalies[project][class_name];
            if (!counts_by_type?.[class_name]) {
              counts_by_type[class_name]
                  = group_anomalies[project][class_name];
            }
            else {
              counts_by_type[class_name]
                  += group_anomalies[project][class_name];
            }
          }
        }

        row.push(total);
        contents[index].table.body.push(row);
      });
      grand_total += total;
    }

    chunks.forEach((chunk, index) => {
      let grand_total_row = ['Grand Total'];
      chunk.forEach(text => grand_total_row.push(counts_by_type[text] || '0'));
      grand_total_row = [...grand_total_row, grand_total];
      contents[index].table.body.push(grand_total_row);
    });

    contents = contents.map((item, index) => {
      if (index !== contents.length - 1) {
        item.table.body.forEach((row) => {
          row.pop();
        });
        item.table.widths.pop();
      }

      return item;
    });
    return contents;
  }
  function createSummaryPage(summary_data) {
    const { severity } = summary_data;
    return [
      {
        text: 'Defects Summary',
        style: 'h4',
        margin: [0, 0, 0, 20],
      },
      createSummaryTable(summary_data),
      {
        text: 'Temperature Delta Histogram',
        bold: true,
        fontSize: 11,
        alignment: 'center',
        margin: [0, 30, 0, 0],
      },
      {
        image: summary_data.temperature_histogram,
        margin: [0, 15, 0, 30],
        fit: [515, 300],
      },
      { image: summary_data.severity_pie, margin: [0, 15, 0, 30], fit: [300, 300] },
      {
        alignment: 'center',
        columns: [
          {
            table: {
              widths: [60, 'auto', 'auto'],
              body: [
                [
                  { text: 'Severity', style: 'table_header' },
                  { text: 'Description', style: 'table_header' },
                  { text: 'Number of anomalies', style: 'table_header' },
                ],
                ['Severity 1', 'The anomaly is not severe enough to warrant immediate action. For example - Dirt, Shadow, Vegetation, Suspected Soiling.', severity['Severity 1']],
                ['Severity 2', 'These are anomalies whose temperature difference < 40°C and not under severity level 1. It is necessary to conduct an inspection to identify root cause and rectify the anomaly within a short period of time.', severity['Severity 2']],
                ['Severity 3', 'These are anomalies which are > 40°C and are safety hazards. These need to be addressed immediately.', severity['Severity 3']],
                [
                  'Total',
                  '-',
                  Object.values(severity).reduce(
                    (prev, curr) => prev + curr,
                    0,
                  ),
                ],
              ],
            },
            layout: 'nice_table',
          },
        ],
      },
      {
        text: '',
        pageBreak: 'after',
      },
    ];
  }
  function taskFieldTemperature(value) {
    if (value)
      return `${value.toFixed(2)} °C`;
    return value === 0 ? '0 °C' : 'N/A';
  }
  function taskFieldDate(value) {
    return value
      ? new Date(value).toLocaleDateString(undefined, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })
      : 'Not set';
  }
  function createImageColumns(images, maxLength) {
    const image_columns = [];
    let images_in_col = [];

    for (const [index, image_src] of images) {
      const image_margin = [images_in_col.length ? 10 : 0, 10, 0, 0];

      const image_obj = {
        image: image_src,
        width: 250,
        height: 250,
        margin: image_margin,
      };

      if (index === maxLength - 1)
        image_obj.pageBreak = 'after';

      images_in_col.push(image_obj);

      if (images_in_col.length === 2) {
        // 2 images per row
        image_columns.push(images_in_col);
        images_in_col = [];
      }
    }

    return { image_columns, images_in_col };
  }
  function taskFieldDefaultValue(expresion_value, default_value) {
    if (expresion_value === undefined || expresion_value === null)
      return default_value || 'N/A';
    return expresion_value;
  }
  function generateTaskField(
    column_name,
    column_value,
    column_name_options = {},
    column_value_options = {},
  ) {
    return [
      ...generateKeyColumns(column_name, column_name_options),
      ...generateValueColumns(column_value, column_value_options),
    ];
  }
  function generateTaskDetails(task_details) {
    const result = [];
    // group details 2 by 2
    for (let i = 0; i < task_details.length - 1; i += 2) {
      result.push([
        ...generateTaskField(
          task_details[i].key,
          task_details[i].value,
          task_details[i].key_options,
          task_details[i].value_options,
        ),
        ...generateTaskField(
          task_details[i + 1].key,
          task_details[i + 1].value,
          task_details[i + 1].key_options,
          task_details[i + 1].value_options,
        ),
      ]);
    }

    return result;
  }
  async function createSummaryData(anomalies) {
    const QuickChartJsImage = (await import('quickchart-js')).default;

    const class_names = [];
    const group_anomalies = {};
    const unordered_histogram_data = {};
    const histogram = new QuickChartJsImage();
    const pie_chart = new QuickChartJsImage();
    const severity = {
      'Severity 1': 0,
      'Severity 2': 0,
      'Severity 3': 0,
    };

    for (const proj in projects.value)
      group_anomalies[projects.value[proj].name] = {};

    for (const anomaly of anomalies) {
      if (!anomaly.feature)
        continue;

      const class_name = class_ids.value[
        anomaly.feature.properties.class_id
      ];
      const project_name = projects.value[
        anomaly.feature.properties.projectUid
      ].name;

      const diff = anomaly.feature.properties.temperature_difference;

      if (!class_names.includes(class_name))
        class_names.push(class_name);

      if (!group_anomalies?.[project_name]?.[class_name]) {
        group_anomalies[project_name][class_name] = 1;
      }

      else {
        group_anomalies[project_name][class_name]
            = group_anomalies[project_name][class_name] + 1;
      }

      if (diff) {
        const ceil = Math.ceil(diff);
        const floor = Math.floor(diff);
        const key
            = floor === ceil ? `${floor}-${floor + 1}` : `${floor}-${ceil}`;
        if (!unordered_histogram_data[key])
          unordered_histogram_data[key] = 1;

        else
          unordered_histogram_data[key] += 1;
      }

      // How anomalies can be categorized based on severity
      // Severity 3: Temperature Difference is greater than 40 degrees
      // Severity 1: Anomaly type is Dirt, Vegetation, Suspected Soiling or Shadow
      // Severity 2: All other anomalies (electrical faults - hotspots, bypass diode, string failures, tracker malfunction etc.)

      if (diff && diff > 40)
        severity['Severity 3']++;

      else if (
        [108, 109, 303, 315].includes(anomaly.feature.properties.class_id)
      )
        severity['Severity 1']++;

      else
        severity['Severity 2']++;
    }

    const ordered_histogram_data = sortObjectByKey(unordered_histogram_data);

    const length_of_keys = Object.keys(ordered_histogram_data || {}).length;
    histogram.setConfig({
      type: 'bar',
      options: {
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Temperature Range (°C)',
                font: {
                  size: 20,
                },
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Number of defects',
                font: {
                  size: 20,
                },
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
      data: {
        labels: Object.keys(ordered_histogram_data),
        legend: {
          display: false,
        },
        datasets: [
          {
            label: 'Temperature',
            backgroundColor: Array.from({ length: length_of_keys }).fill('#0d84d5'),
            data: Object.values(ordered_histogram_data),
          },
        ],
      },
    });
    histogram.setWidth(854).setHeight(480).setBackgroundColor('transparent');

    pie_chart.setConfig({
      type: 'pie',
      data: {
        labels: Object.keys(severity).map(val => `${val} (${severity[val]})`),
        datasets: [
          {
            label: 'Severity',
            backgroundColor: ['#ffbb43', '#ff8652', '#c13525'],
            data: Object.values(severity),
          },
        ],
      },
    });
    pie_chart.setWidth(350).setHeight(350);

    const unsorted_group_anomalies = pickBy(group_anomalies);
    const sorted_group_anomalies = sortObjectByKey(
      unsorted_group_anomalies,
    );

    return {
      group_anomalies: sorted_group_anomalies,
      class_names,
      severity,
      severity_pie: await pdf_exporter.imageToDataURL(pie_chart.getUrl()),
      temperature_histogram: await pdf_exporter.imageToDataURL(histogram.getUrl()),
    };
  }
  async function createCommentsTable(comments, prefix = 0) {
    const content = [];
    let index = 0;
    for (const comment of comments) {
      const row = [];

      if (comment.type === 'deleted' || !comment.text)
        continue;

      const index_number = prefix
        ? `${prefix}.${index + 1}`
        : `${index + 1}`;
      const indentation_level = index_number.split('.').length - 1;

      const temp_row = [
        index_number,
        (indentation_level
          ? `—  ${htmlToText(comment.text)}`
          : (htmlToText(comment.text) || '')
        ).trim(),
        common_store.get_user_or_team_name(comment.owner.uid) || '',
        $date(comment.createdAt, 'L_DATETIME_MED'),
      ].map(text => createValueCell(text));

      temp_row[1].margin = [indentation_level * 20, 0, 0, 0];

      row.push(...temp_row);
      content.push(row);
      index++;
    }
    return content;
  }
  function decodeHtml(html) {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }
  async function createDocumentContent(anomalies, links_by_task) {
    const document_content = [];
    function stripTags(str, tagsToRemove = ['table']) {
      const decoded_str = decodeHtml(str);
      const regex
          = tagsToRemove.length > 0
            ? new RegExp(
              `(<(${tagsToRemove.join(
                '|',
              )})\\b[^>]*>[\\s\\S]*?<\\/\\2>)|(<[^>]+>)`,
              'gi',
            )
            : /<[^>]*>/g; // (<[^>]*?>)/g Try this in future if current one runs into issues
      return decoded_str.replace(regex, '');
    }

    function getFeatureData(anomaly, feature, type) {
      if (type === 'status') {
        const status = (therm_store.use_tasks || !feature) ? status_codes?.[anomaly.task?.status] : therm_store.status_map?.[feature?.properties?.status];
        return {
          key: 'Status',
          key_options: {},
          value: taskFieldDefaultValue(status?.name, 'N/A'),
          value_options: status?.color ? { color: status.color } : {},
        };
      }
      if (type === 'priority') {
        const priority = (therm_store.use_tasks || !feature) ? priority_codes?.[anomaly.task?.priority] : therm_store.priority_map?.[feature?.properties?.priority];
        return {
          key: 'Priority',
          key_options: {},
          value: taskFieldDefaultValue(priority?.name, (therm_store.use_tasks || !feature) ? 'Not set' : 'N/A'),
          value_options: priority?.color ? { color: priority.color } : {},
        };
      }
    }

    for (const anomaly of anomalies) {
      let task_columns = [];

      const feature = anomaly?.feature;
      const feature_type_id = feature?.properties?.featureTypeId;
      const string_number = feature?.properties?.string_number;
      const name = therm_store.feature_types[feature_type_id] ? `: ${therm_store.feature_types[feature_type_id]?.name}` : '';
      const timestamp = feature?.properties?.timestamp
        ? `${dayjs(feature.properties.timestamp.slice(0, 10), 'YYYY:MM:DD').format('DD MMM YYYY')} ${feature.properties.timestamp.slice(10)}`
        : null;
      const anomaly_obj = [
        {
          key: 'Name',
          key_options: [0, 20, 0, 10],
          value: feature
            ? (`${string_number} ${name}`)
            : (anomaly?.task?.name || 'N/A'),
          value_options: [0, 20, 0, 10],
        },

        {
          key: 'Type',
          key_options: [0, 20, 0, 10],
          value: feature
            ? taskFieldDefaultValue(
              class_ids.value?.[feature.properties.class_id],
              'N/A',
            )
            : 'N/A',
          value_options: [0, 20, 0, 10],
        },

        {
          key: 'Block',
          key_options: {},
          value:
              projects.value?.[
                feature?.properties?.projectUid
                || anomaly.task?.properties?.project_uid
              ]?.name || 'N/A',
          value_options: {},
        },

        {
          key: 'Temperature',
          key_options: {},
          value: feature
            ? taskFieldTemperature(
              feature.properties.temperature_difference,
            )
            : 'N/A',
          value_options: {},
        },

        {
          key: 'Description',
          key_options: {},
          value: anomaly.task?.description
            ? stripTags(
              taskFieldDefaultValue(anomaly.task?.description),
            )
            : 'N/A',
          value_options: {},
        },

        getFeatureData(anomaly, feature, 'status'),

        {
          key: 'Asset',
          key_options: {},
          value: current_asset_name,
          value_options: {},
        },
        getFeatureData(anomaly, feature, 'priority'),

        {
          key: 'Date',
          key_options: {},
          value: taskFieldDate(anomaly.task?.due_date),
          value_options: {},
        },
        {
          key: 'Coordinates',
          key_options: {},
          value: taskFieldDefaultValue(
            feature
              ? feature.properties?.center[0].map(item => item.toFixed(5))?.toReversed().join(', ')
              : anomaly.task?.location?.coordinates?.map(item => item.toFixed(5))?.toReversed().join(', '),
            'N/A',
          ),
          value_options: {
            link: `https://www.google.com/maps?q=${feature
              ? feature.properties?.center[0].toReversed().join(',')
              : anomaly.task?.location?.coordinates?.toReversed().join(',')}`,
            color: '#007bff',
          },
        },

        {
          key: 'Timestamp',
          key_options: {},
          value: feature
            ? taskFieldDefaultValue(timestamp, 'N/A')
            : 'N/A',
          value_options: {},
        },

        ...((feature?.properties?.serial_number)

          ? [
              {
                key: 'Module serial number',
                key_options: {},
                value: taskFieldDefaultValue(
                  feature
                    ? feature?.properties?.serial_number
                    : 'N/A',
                ),
                value_options: {},
              },
              {},
            ]
          : []),
        ...((feature?.properties?.yield_loss_estimate
          || anomaly.task?.properties?.yield_loss_estimate)
          ? [
              {
                key: 'Yield loss estimate',
                key_options: {},
                value: taskFieldDefaultValue(
                  feature
                    ? feature?.properties?.yield_loss_estimate
                    : anomaly.task?.properties?.yield_loss_estimate,
                  'N/A',
                ),
                value_options: {},
              },
              {},
            ]
          : []),
        ...((feature?.properties?.adjusted_delta_temperature
          || anomaly.task?.properties?.adjusted_delta_temperature)
          ? [
              {
                key: 'Adjusted delta temperature',
                key_options: {},
                value: taskFieldDefaultValue(
                  feature
                    ? feature?.properties?.adjusted_delta_temperature
                    : anomaly.task?.properties?.adjusted_delta_temperature,
                  'N/A',
                ),
                value_options: {},
              },
              {},
            ]
          : []),
        ...((feature?.properties?.power_loss_estimate
          || anomaly.task?.properties?.power_loss_estimate)
          ? [
              {
                key: 'Power loss estimate',
                key_options: {},
                value: taskFieldDefaultValue(
                  feature
                    ? feature?.properties?.power_loss_estimate
                    : anomaly.task?.properties?.power_loss_estimate,
                  'N/A',
                ),
                value_options: {},
              },
              {},
            ]
          : []),
      ];

      task_columns = generateTaskDetails(anomaly_obj);

      const table_for_task = {
        table: {
          headerRows: 0,
          body: [],
          widths: '*',
        },
        layout: 'noBorders',
        unbreakable: true,
      };

      let max_col_length = 0;
      task_columns.forEach((column) => {
        const col_length = column.length;
        if (col_length > max_col_length)
          max_col_length = col_length;
        if (col_length < max_col_length) {
          const modified_columns = [...column];
          for (
            let length = col_length + 1;
            length <= max_col_length;
            length += key_styles.colSpan + value_styles.colSpan
          ) {
            modified_columns.push(
              ...generateKeyColumns(''),
              ...generateValueColumns(''),
            );
          }

          table_for_task.table.body.push(modified_columns);
        }
        else {
          table_for_task.table.body.push(column);
        }
      });

      document_content.push(table_for_task);
      if (!isExporting())
        throw new Error('PDF export stopped.');
      const comments = task_comments_map.value?.[anomaly?.task?.uid]?.sort(
        (a, b) => new Date(a.createdAt) > new Date(b.createdAt),
      );
      if (therm_store.use_tasks && anomaly.task?.comments_count && comments?.length) {
        document_content.push(
          { text: 'Comments', style: 'subheader', margin: [0, 20, 0, 10] },
          {
            style: 'simple_table',
            margin: [0, 0, 0, 20],
            table: {
              widths: ['auto', '*', '*', '*'],
              body: [
                ['#', 'Comment', 'Author', 'Commented At'].map(text =>
                  createKeyCell(text),
                ),
                ...(await createCommentsTable(comments)),
              ],
            },
          },
        );
      }
      if (options.show_notes && feature?.properties?.notes?.length) {
        document_content.push(
          { text: 'Notes', style: 'subheader', margin: [0, 20, 0, 10] },
          {
            style: 'simple_table',
            margin: [0, 0, 0, 20],
            table: {
              widths: ['auto', '*', '*', '*'],
              body: [
                ['#', 'Note', 'Author', 'Created At'].map(text =>
                  createKeyCell(text),
                ),
                ...(await createCommentsTable(feature.properties.notes.map(note => ({
                  ...note,
                  text: note.message,
                  createdAt: note.created_at,
                })))),
              ],
            },
          },
        );
      }
      const updated_feature = therm_store.features_hash[feature?.properties?.uid];
      const raw_images = (updated_feature || feature)?.properties?.raw_images || [];
      const manually_added_images = [
        ...(feature?.properties?.user_attachments || []),
        ...(therm_store.use_tasks ? (links_by_task?.[anomaly.task?.uid] || []) : []),
      ];
      const image_urls = [];
      if (options.show_raw_images)
        image_urls.push(...((Array.isArray(raw_images) ? raw_images : []).map(obj => obj.src) || []));
      if (options.show_attachments)
        image_urls.push(...(manually_added_images?.map(image => image.url) || []));

      if (image_urls?.length) {
        const promises = [];
        let image_columns = [];
        let images_in_col = [];

        image_urls.forEach(url => promises.push(pdf_exporter.imageToDataURL(url)));
        const image_srcs = await Promise.all(promises);

        const generated_image_columns = createImageColumns(
          image_srcs.entries(),
          image_urls.length,
        );

        image_columns = generated_image_columns.image_columns;
        images_in_col = generated_image_columns.images_in_col;

        if (images_in_col.length)
          image_columns.push(images_in_col);

        image_columns.forEach((columns) => {
          document_content.push({ columns });
        });
      }
      else {
        document_content.push({
          text: '',
          pageBreak: 'after',
        });
      }
    }
    return document_content;
  }
  async function downloadPDF({ current_index, total_pdf_count, generated_blob }) {
    let filename = `${current_organization_name} - ${current_asset_name} - ${block_names_list.value}`;
    if (total_pdf_count > 1)
      filename = `${filename} - Part ${current_index} of ${total_pdf_count}`;

    filename = filename.replace(/[^\w ]+/g, '').replace(/ +/g, '-');
    filename = `${filename}.pdf`;

    if (!isExporting())
      return;

    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    anchor.href = window.URL.createObjectURL(generated_blob);
    anchor.download = filename;
    anchor.click();
  }
  async function createPDF(all_anomalies) {
    const $services = markRaw(services);
    const chunked_anomalies_array = chunk(all_anomalies, 100);
    const total_pdf_count = chunked_anomalies_array.length;
    total_files_to_export.value = chunked_anomalies_array.length;
    updatePdfExportProgress({
      current_export_file_index: 1,
      total_files_to_export: total_files_to_export.value,
    });
    let attachments = [];
    const tasks = compact(all_anomalies.map(anomaly => anomaly.task?.uid));
    if (therm_store.use_tasks && tasks.length) {
      if (!isExporting())
        return;
      if (options.show_attachments) {
        const { data } = await $services.tasks.post({
          attribute: 'attachments',
          body: { tasks },
        });
        attachments = data?.attachments || [];
      }
      const { data: comments_data } = await $services.tasks.post({
        attribute: 'comments',
        body: { tasks },
      });
      task_comments_map.value = comments_data.data;
    }

    const image_attachments = attachments.filter(attachment =>
      // eslint-disable-next-line regexp/no-unused-capturing-group
      /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(attachment.file_name),
    );
    const links_by_task = groupBy(
      image_attachments,
      'foreign_object_uid',
    );

    const header = {
      columns: [
        ...(!auth_store.check_split('remove_therm_report_logos')
          ? [{
              image: await pdf_exporter.getAppLogo(),
              width: 100,
              margin: [40, 25, 0, 20],
            }]
          : []),
      ],
    };
    if (!isExporting())
      return;
    const orgLogoDataURL = await pdf_exporter.getOrgLogo(false);
    if (orgLogoDataURL) {
      header.columns = [
        {
          image: orgLogoDataURL,
          fit: [150, 30],
          margin: [40, 25, 0, 20],
        },
      ];
    }

    const config = {
      header,
      footer: 'confidential', // the styles for this are defined inside the generator
      defaultStyle: {
        font: 'Roboto',
        fontSize: 10,
      },
      styles: {
        h1: {
          fontSize: 38,
          bold: false,
          alignment: 'justify',
        },
        h2: {
          fontSize: 27,
          bold: true,
          alignment: 'justify',
        },
        h3: {
          fontSize: 35,
          bold: false,
          alignment: 'justify',
          margin: [0, 0, 0, 10],
        },
        h4: {
          fontSize: 18,
          bold: false,
          alignment: 'justify',
          margin: [0, 0, 0, 10],
        },
        smol: {
          fontSize: 9,
        },
        smol_bold: {
          fontSize: 9,
          bold: true,
        },
        italics: {
          italics: true,
        },
        table_header: {
          bold: true,
          fontSize: 10,
          color: 'white',
          alignment: 'center',
        },
        bold_10: {
          font: 'Roboto',
          fontSize: 10,
        },
        simple_10: {
          font: 'Roboto',
          fontSize: 10,
        },
      },
      pageMargins: [40, 80, 40, 60],
      generated_by: common_store.get_user_or_team_name(auth_store?.logged_in_user_details?.user_id),
      generated_at: date_now_local,
    };

    let summary_content = null;
    if (!isExporting())
      return;

    const summary = await createSummaryData(all_anomalies);
    if (summary)
      summary_content = createSummaryPage(summary);

    if (!isExporting())
      return;

    const { wrapped_worker, worker } = await pdf_exporter.createPDFGeneratorWebWorker();
    try {
      if (!all_anomalies?.length) {
        const content = createCover({ current_index: 1, total_pdf_count });
        if (summary)
          content.push(...summary_content);
        if (!isExporting())
          throw new Error('PDF export stopped!');
        const generated_blob = await wrapped_worker.generatePDF(content, [], config);
        await downloadPDF({ current_index: 1, total_pdf_count, generated_blob });
      }
      else {
        for (const [index, anomalies] of chunked_anomalies_array.entries()) {
          const content = createCover({ current_index: index + 1, total_pdf_count });
          current_export_file_index.value = index + 1;
          updatePdfExportProgress({
            current_export_file_index: current_export_file_index.value,
            total_files_to_export: total_files_to_export.value,
          });
          if (!index && summary)
            content.push(...summary_content);

          const dd_promises = chunk(anomalies, 10).map(ch =>
            createDocumentContent(ch, links_by_task),
          );
          if (!isExporting())
            throw new Error('PDF export stopped!');

          const dd_content = (await Promise.all(dd_promises))?.flat();
          content.push(...dd_content);
          if (!isExporting())
            throw new Error('PDF export stopped!');

          const generated_blob = await wrapped_worker.generatePDF(content, [], config);
          await downloadPDF({ current_index: index + 1, total_pdf_count, generated_blob });
        }
      }
    }
    catch (error) {
      logger.error(error);
    }
    finally {
      worker.terminate();
    }
  }
  async function exportPDF(selected_features) {
    if (!isExporting())
      return;
    const tasks_store = useTasksStore('therm_tasks_store');

    current_export_file_index.value = 1;
    updatePdfExportProgress({
      current_export_file_index: current_export_file_index.value,
      total_files_to_export: total_files_to_export.value,
    });
    const features = selected_features || [];
    const unfiltered_features = [];
    for (let i = 0; i < therm_store.active_projects.length; i++) {
      const project = therm_store.active_projects[i];
      await therm_store.set_project_features({
        project,
        forceUpdate: true,
        query: {
          parse_raw_images: true,
        },
      });
      Object.keys(therm_store.projects_features_map[project.uid] || {}).forEach((key) => {
        unfiltered_features.push(...(therm_store.projects_features_map[project.uid][key] || []));
        if (!therm_store.inactive_feature_types_map[key] && !selected_features?.length) {
          const filtered = therm_store.projects_features_map[project.uid][key];
          features.push(...filtered);
        }
      });
    }

    const features_hash = keyBy(features, item => item.properties.uid);
    const unfiltered_features_hash = keyBy(
      unfiltered_features,
      item => item.properties.uid,
    );

    const filtered_anomalies = [];
    const added_task_uids = [];

    Object.values(tasks_store?.tasks_map || {}).forEach((task) => {
      if (unfiltered_features_hash[task?.properties?.issue_uid])
        added_task_uids.push(task.uid);
    });
    therm_store.features.forEach((feature) => {
      if (features_hash[feature.properties.uid]) {
        filtered_anomalies.push({
          feature: features_hash[feature.properties.uid],
          task: therm_store.tasks_hash[feature.properties.uid],
        });
      }
    });

    const filtered_tasks = therm_store.filtered_defects(
      Object.values(tasks_store?.tasks_map || {}),
      'tasks',
    );

    const tasks = filtered_tasks.filter(
      task => !added_task_uids.includes(task.uid),
    );

    if (options.show_tasks_unassociated) {
      for (const task of tasks) {
        if (tasks_store.tasks_map?.[task?.uid]) {
          filtered_anomalies.push({
            feature: null,
            task: tasks_store.tasks_map?.[task?.uid],
          });
        }
      }
    }

    if (!isExporting())
      return;
    await createPDF(filtered_anomalies);
    return Promise.resolve('download complete');
  }

  return { exportPDF };
}
