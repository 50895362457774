<script setup>
import { saveAs } from 'file-saver';
import { computed, reactive, watch } from 'vue';
import { useGalleryView } from '~/terra/utils/gallery-view.composable.js';

const props = defineProps({
  store: {
    type: Object,
  },
  available_attachments_map: {
    type: Object,
  },
});
const emits = defineEmits(['activeAttachment', 'close', 'updateAttachmentCounts']);

const { getNearByFeatures, sortAttachments } = useGalleryView();

const state = reactive({
  is_loading: false,
  file_downloading: false,
  near_by_attachments: [],
});

const feature_details = computed(() => {
  const { active_attachment } = props.store.gallery_view_state;
  const { project_uid, feature_uid } = active_attachment;
  const active_feature = props.store.formatted_project_features_attachment_map[project_uid][feature_uid];

  let active_attachment_thumbnail = (active_attachment?.thumbnail_large || active_attachment.thumbnails?.large) || (active_attachment.thumbnail_small || active_attachment.thumbnails?.small);
  if (!active_attachment_thumbnail && ['.jpg', '.png', '.jpeg'].some(type => active_attachment.file_name.includes(type)))
    active_attachment_thumbnail = active_attachment?.url || active_attachment?.service?.url;

  return {
    active_attachment,
    active_attachment_thumbnail,
    active_feature,
    all_feature_attachments: active_feature.all_feature_attachments,
  };
});

async function downloadAttachment(file) {
  state.file_downloading = file.uid;
  const response = await fetch(file?.url || file?.service?.url);
  const response_blob = await response.blob();
  saveAs(response_blob, file?.file_name);
  state.file_downloading = null;
}

async function getNearByAttachments() {
  state.is_loading = true;

  const { active_attachment } = props.store.gallery_view_state;
  const { feature_uid } = active_attachment;
  const near_by_features = await getNearByFeatures(props.store, feature_uid);

  state.near_by_attachments = [];
  for (const value of near_by_features) {
    if (state.near_by_attachments.length > 8)
      break;

    const { project_uid, feature_uid } = value?.feature?.properties || {};
    const all_feature_attachments = props.store.formatted_project_features_attachment_map?.[project_uid]?.[feature_uid]?.all_feature_attachments || [];
    state.near_by_attachments.push(...all_feature_attachments.filter(attachment => !!props.available_attachments_map?.[attachment.uid]));
  }
  state.is_loading = false;
}
function updateFeatureCount() {
  const { feature_uid, attachments_count } = feature_details.value.active_feature;
  const source = props.store.map.getSource('feature_attachment_points');
  if (!source)
    return;
  const data = source._data;

  data.features.forEach((feature) => {
    if (feature.properties.feature_uid === feature_uid)
      feature.properties.attachments_count = attachments_count;
  });

  source.setData(data);
}
watch(() => feature_details.value.active_attachment?.feature_uid, async () => {
  await getNearByAttachments();
});

watch(() => feature_details.value.active_feature?.attachments_count, async (new_val, old_val) => {
  if (new_val !== old_val)
    updateFeatureCount();
});

watch(() => props.available_attachments_map[feature_details.value.active_attachment?.uid], (val) => {
  if (!val)
    emits('close');
});

watch(() => props.store.selected_features, (features) => {
  if (!features?.length || features.some(feature => feature?.properties?.uid !== feature_details.value.active_feature?.feature_uid))
    emits('close');
});

getNearByAttachments();
</script>

<template>
  <div class="w-full px-4 sm:px-6">
    <div class="flex items-center justify-between min-w-[330px] max-w-[360px] bg-white">
      <div class="flex items-center gap-x-2">
        <div class="w-5 h-5 cursor-pointer" @click="emits('close')">
          <IconHawkArrowLeft class="w-5 h-5" />
        </div>
        <div class="text-sm font-semibold text-gray-900">
          {{ $t('Go back to gallery') }}
        </div>
      </div>
      <div>
        <IconHawkX
          class="w-6 h-6 cursor-pointer text-gray-600 hover:text-gray-900  outline-none"
          tabindex="0"
          @click="emits('closeSlider')"
        />
      </div>
    </div>
  </div>
  <div class="px-4 sm:px-6 mt-4 overflow-y-scroll !overflow-x-hidden scrollbar h-full">
    <div :key="feature_details.active_attachment.uid" class="mb-4 relative">
      <img
        v-if="feature_details.active_attachment_thumbnail"
        :src="feature_details.active_attachment_thumbnail"
        class="object-cover rounded-lg w-[360px] h-[360px]"
        alt="thumbnail"
      >
      <div
        v-else
        class="flex items-center justify-center bg-gray-100 w-[360px] h-[360px]"
      >
        <HawkFileIcon :file_name="feature_details.active_attachment.file_name" />
      </div>
      <div class="absolute top-0 flex justify-end w-full p-2">
        <HawkButton
          type="outlined" class="!px-2"
          :loading="state.file_downloading === feature_details.active_attachment.uid"
          @click="downloadAttachment(feature_details.active_attachment)"
        >
          <IconHawkDownloadOne />
        </HawkButton>
      </div>
      <div class="absolute bottom-0 flex justify-between items-center p-2 w-full rounded-b-lg">
        <div>
          <HawkMembers :members="feature_details.active_attachment.created_by" size="xs" type="label" :has_avatar="true" name_classes="text-xs font-semibold text-white" />
        </div>
        <div v-if="feature_details.active_attachment.created_at">
          <HawkText class="flex-1 text-xs text-white" :length="50" :content="`${$date(feature_details.active_attachment.created_at, 'L_DATETIME_MED')}`" />
        </div>
      </div>
    </div>

    <div class="flex flex-wrap gap-2">
      <div
        v-for="item in sortAttachments(feature_details.all_feature_attachments)" :key="item.uid"
        class="rounded-lg cursor-pointer" :class="[item.uid === feature_details.active_attachment?.uid ? 'border-primary-600 border-4' : 'border-white border-y-4']"
        @click="emits('activeAttachment', item)"
      >
        <img
          v-if="item.thumbnail_small || (item.thumbnails?.small)" :src="item.thumbnail_small || (item.thumbnails?.small)"
          class="object-cover w-12 h-12"
          :class="[item.uid === feature_details.active_attachment?.uid ? 'rounded' : 'rounded-lg']"
          alt="thumbnail"
        >
        <div
          v-else
          class="flex items-center justify-center bg-gray-100 w-12 h-12 rounded-lg"
        >
          <HawkFileIcon :file_name="item.file_name" />
        </div>
      </div>
    </div>

    <div class="my-4">
      <slot name="feature_details" />
    </div>
    <div v-if="state.is_loading">
      <hawk-loader />
    </div>

    <div v-else-if="state.near_by_attachments.length">
      <div class="text-xs text-gray-600 mb-2">
        {{ $t('Other images') }}
      </div>
      <div class="flex flex-wrap gap-2">
        <div
          v-for="item in sortAttachments(state.near_by_attachments.slice(0, 8))" :key="item.uid"
          class="cursor-pointer"
          @click="emits('activeAttachment', item)"
        >
          <img
            v-if="item.thumbnail_small || (item.thumbnails?.small)" :src="item.thumbnail_small || (item.thumbnails?.small)"
            class="object-cover rounded-lg w-20 h-20"
            alt="thumbnail"
          >
          <div
            v-else
            class="flex items-center justify-center rounded-lg bg-gray-100 w-20 h-20"
          >
            <HawkFileIcon :file_name="item.file_name" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
