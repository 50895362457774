<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { $t, route, router } = useCommonImports();

const sidebar_top_items = computed(() => [
  {
    uid: 'general',
    label: $t('General'),
    to: { name: !route?.params?.asset_id ? 'account-settings-general' : 'account-settings-asset-general' },
    icon: IconHawkSettingsOne,
  },
  {
    uid: 'users',
    label: $t('Users'),
    to: { name: 'account-settings-users' },
    icon: IconHawkUserOne,
  },
  {
    uid: 'teams',
    label: $t('Teams'),
    to: { name: 'account-settings-teams' },
    icon: IconHawkUsersOne,
  },
  {
    uid: 'roles',
    label: $t('Roles'),
    to: { name: 'account-settings-roles' },
    icon: IconHawkUserSquare,
  },
  ...(!route?.params?.asset_id
    ? [{
        uid: 'categories',
        label: $t('Categories & Tags'),
        to: { name: 'account-settings-categories' },
        icon: IconHawkLayoutGridOne,
      }]
    : [{
        uid: 'categories',
        label: $t('Categories'),
        to: { name: 'account-settings-asset-categories' },
        icon: IconHawkLayoutGridOne,
      }]),
  ...(!route?.params?.asset_id
    ? [{
        uid: 'asset_settings',
        label: $t('Asset properties'),
        to: { name: 'account-settings-asset-fields' },
        icon: IconHawkLayersTwoTwo,
      },
      {
        uid: 'integrations',
        label: $t('Integrations'),
        to: { name: 'account-settings-integrations' },
        icon: IconHawkLinkFour,
      },
      {
        uid: 'audit_logs',
        label: $t('Audit logs'),
        to: { name: 'account-settings-audit-logs' },
        icon: IconHawkLogInOne,
      }]
    : []),
]);
</script>

<template>
  <div class="flex">
    <hawk-sidebar-navigation :sidebar_top_items="sidebar_top_items" />
    <div class="w-[calc(100vw-80px)] flex-1 pl-20 relative mx-4">
      <router-view />
    </div>
  </div>
</template>
