<script setup>
import dayjs from 'dayjs';
import { useModal } from 'vue-final-modal';
import AcctSettingsDefaultRolesGlobalAdmins from '../components/acct-settings-default-roles/acct-settings-default-roles-global-admins.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import AcctSettingsDuplicateRole from '~/acct-settings/components/acct-settings-duplicate-role.vue';

const { $t, $toast, auth_store, common_store, route, router, $track_event } = useCommonImports();
const table_columns = [
  {
    id: 'name',
    header: $t('Name'),
    accessorKey: 'name',
  },
  {
    header: $t('Created on'),
    accessorKey: 'created_at',
    id: 'created_at',
  },
  {
    header: $t('Members'),
    accessorKey: 'users',
    id: 'users',
  },
  {
    header: '',
    accessorKey: 'context_menu',
    id: 'context_menu',
    show_on_hover: true,
    size: 5,
  },
];
const roles = computed(() => {
  return common_store?.scope_roles(route?.params?.asset_id);
});
const hawk_menu_items = [
  {
    label: $t('Duplicate'),
    uid: 'duplicate',
  },
  {
    label: $t('Edit'),
    uid: 'edit',
  },
  {
    label: $t('Delete'),
    uid: 'delete',
  },
];

const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
});

const { open: openDuplicateModal, close: closeDuplicateModal, patchOptions: patchRolesOptions } = useModal({
  component: AcctSettingsDuplicateRole,
});
function roleDuplicateHandler(role) {
  patchRolesOptions(
    {
      attrs: {
        role,
        onClose() {
          closeDuplicateModal();
        },
      },
    },
  );
  openDuplicateModal();
}

function roleDeleteHandler({ name, uid }) {
  patchOptions(
    {
      attrs: {
        header: $t('Delete Role'),
        content: `Are you sure you want to delete ${name || ''}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            const { status } = await common_store.update_data({
              type: 'delete',
              id: uid,
              service: 'roles',
              append_data: true,
              state_prop: 'roles_map',
              update_state: true,
            });
            if (status === 204) {
              $toast({
                title: 'Role deleted',
                text: 'The role has been successfully deleted',
                type: 'success',
              });
              closeDeletePopup();
            }
          }
          catch (err) {
            $toast({
              title: 'Failed to delete role',
              text: 'Unable to delete the role. Please try again',
              type: 'error',
            });
          }
        },
      },
    },
  );
  openDeletePopup();
}
async function onActionClicked(type, role) {
  try {
    switch (type) {
      case 'duplicate':{
        roleDuplicateHandler(role);
        break;
      }
      case 'edit':
        router.push({
          name: 'account-settings-role-details',
          params: {
            id: role.uid,
          },
        });
        break;
      case 'delete':
        roleDeleteHandler(role);
        break;
    }
  }
  catch (error) {
    logger.error('error', error);
  }
}
</script>

<template>
  <div>
    <HawkPageHeader class="!px-0">
      <template #title>
        <div>
          <div>{{ $t('Roles') }}</div>
          <p class="text-sm text-gray-600 font-normal sm:max-w-3xl">
            {{ $t('Configure and manage roles to set specific permission levels for users and teams. Tailor access rights to ensure appropriate resource access and enhance operational security.') }}
          </p>
        </div>
      </template>
      <template #right>
        <HawkButton v-if="auth_store.check_permission('modify_roles', $route.params.asset_id)" @click="router.push({ name: 'account-settings-role-details' })">
          <IconHawkPlus class="text-white" />
          <span>{{ $t('New Role') }}</span>
        </HawkButton>
      </template>
    </HawkPageHeader>
  </div>
  <div v-if="!auth_store.check_permission('view_roles', route.params.asset_id)">
    <HawkIllustrations type="no-permission" for="roles" />
  </div>
  <div v-else>
    <div class="my-4">
      <p class="text-sm text-gray-900 font-semibold">
        {{ $t('Default roles') }}
      </p>
      <p class="text-xs text-gray-600">
        {{ $t('System defined roles for easy user permissions management.') }}
      </p>
    </div>
    <div class="my-4 border rounded-lg border-gray-200">
      <AcctSettingsDefaultRolesOwner
        class="border-b"
      />
      <AcctSettingsDefaultRolesGlobalAdmins
        class="border-b"
      />
      <AcctSettingsDefaultRolesAdminAssets />
    </div>
    <div class="my-4">
      <p class="text-sm text-gray-900 font-semibold">
        {{ $t('Custom roles') }}
      </p>
      <p class="text-xs text-gray-600">
        {{ $t('Create and manage roles with granular permission configuration as per your need.') }}
      </p>
    </div>
    <div v-if="!roles?.length" class="grid place-content-center">
      <HawkIllustrations type="no-data" variant="mini_vertical" for="roles" />
    </div>
    <template v-else>
      <HawkTable
        :key="roles?.length"
        :pagination_config="{ totalRows: roles?.length, pageSize: 25 }"
        :data="roles"
        :columns="table_columns"
        :show_menu_header="false"
        is_gapless
        @rowClicked="e => router.push({
          name: 'account-settings-role-details',
          params: {
            id: e.uid,
          },
        })"
      >
        <template #name="{ data: { row: { original: { name, description } } } }">
          <div>
            <p class="text-sm text-gray-900 font-medium">
              {{ name }}
            </p>
            <p v-if="description" class="text-xs text-gray-600">
              {{ description }}
            </p>
          </div>
        </template>
        <template #created_at="{ data: { row: { original: { created_at } } } }">
          {{ created_at ? dayjs(created_at).format('Do MMMM YYYY') : '' }}
        </template>
        <template #users="{ data }">
          <HawkMembers
            :members="[...data.row.original?.users, ...data.row.original?.teams]"
            type="badge"
            popover_avatar_size="badge"
          />
        </template>
        <template #context_menu="role">
          <HawkMenu
            v-if="auth_store.check_permission('modify_roles', route.params.asset_id)"
            :items="hawk_menu_items"
            position="fixed"
            class="z-[999]" @click.stop
          >
            <template #trigger>
              <IconHawkDotsVertical />
            </template>
            <template #content>
              <div class="p-1 w-48 flex flex-col">
                <HawkButton
                  v-for="item in hawk_menu_items"
                  :key="item.label" type="text"
                  @click="onActionClicked(item.uid, role.data.row.original)"
                >
                  {{ item.label }}
                </HawkButton>
              </div>
            </template>
          </HawkMenu>
        </template>
      </HawkTable>
    </template>
  </div>
</template>

<style lang="scss">
.roles-table tr .table-cell {
  padding-left: 24px!important;
}
</style>
