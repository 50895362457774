<script setup>
import { onMounted } from 'vue';
import { compact, keyBy } from 'lodash-es';
import { useJsonRuleEngine } from '~/common/composables/json-engine.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';

const emit = defineEmits(['updateCount']);
const { loadJsonEngine, runJsonEngine } = useJsonRuleEngine();
const { display_filters, filter_loading, display_filters$, filtered_data, onApplyDisplayFilters } = useDisplayFilters();

const { auth_store, common_store, $toast, $t, $services, route, router } = useCommonImports();

const freeze_table = ref('-1');
const state = reactive({
  search: '',
  invitations: [],
  loading: '',
});

const status_map = {
  sent: {
    title: 'Invited',
    color: 'blue',
  },
  failed: {
    title: 'Expired',
    color: 'yellow',
  },
};

const table_columns = [
  {
    header: $t('Email'),
    accessorKey: 'email',
    id: 'email',
  },
  {
    header: $t('Invited By'),
    accessorKey: 'requested_by',
    id: 'requested_by',
  },
  {
    header: $t('Invited On'),
    accessorKey: 'created_at',
    id: 'created_at',
  },
  {
    header: $t('Status'),
    accessorKey: 'status',
    id: 'status',
  },
  {
    id: 'actions',
    accessorKey: 'actions',
    header: '',
    size: '5',
    show_on_hover: true,
  },
];

const has_permission = computed(() => auth_store.check_permission('invite_users', route.params.asset_id));

async function getData() {
  try {
    filter_loading.value = true;
    const query = {
      network_first: true,
      ...(route?.params?.asset_id ? { asset: route?.params?.asset_id } : {}),
    };
    const { data } = await $services.users.get_invites({
      organization_id: auth_store.current_organization?.uid,
      query,
    });
    state.invitations = data.invitations;
    emit('updateCount', { invites: state.invitations?.length });
    filter_loading.value = false;
  }
  catch (error) {
    logger.error(error);
  }
  finally {
    filter_loading.value = false;
  }
}

function toggleExpanded(uid) {
  const index = state.invitations.findIndex(log => log.uid === uid);
  state.invitations[index].is_expanded = !state.invitations[index].is_expanded;
}
function getInvitesDetail(row) {
  return {
    Type: row?.user_type || '',
    Assets: compact(row?.assets),
    Teams: compact(row?.teams),
    Roles: compact(row?.roles),
  };
}

function useDisplayFilters() {
  const display_filters = computed(() => {
    return [
      {
        name: $t('Status'),
        is_static: true,
        data_type: 'single_select',
        operators: ['isAnyOf'],
        options: [
          { name: 'Invited', uid: 'sent' },
          { name: 'Expired', uid: 'invited' },
        ],
        uid: 'email_status',
        search: false,
      },
      {
        name: $t('Invited By'),
        is_static: true,
        data_type: 'single_select',
        operators: ['isAnyOf'],
        option_type: 'member',
        options: [
          ...common_store.scope_users(route.params?.asset_id || null),
        ],
        type: 'select',
        uid: 'requested_by',
      },
    ];
  });
  const display_filters$ = ref();
  function getJSONRules() {
    return {
      all: [
        {
          any: state.search
            ? [
                { fact: 'email', operator: 'stringContains', value: state.search },
              ]
            : [],
        },
        {
          all: (display_filters$.value?.filters || [])
            .map(item => ({
              fact: item.field,
              operator: item.operator,
              value: item.value,
            })),
        },
      ],
    };
  }

  const filter_loading = ref();
  const filtered_data = ref([]);
  const facts_hash = computed(() => {
    return keyBy(state.invitations, 'uid');
  });

  async function onApplyDisplayFilters() {
    filter_loading.value = true;
    try {
      filtered_data.value = await runJsonEngine(getJSONRules(), facts_hash.value, state.invitations);
    }
    catch (err) {
      logger.error(err);
    }
    filter_loading.value = false;
  }
  return {
    display_filters,
    display_filters$,
    onApplyDisplayFilters,
    filtered_data,
    filter_loading,
  };
}

async function resendInvite(user) {
  try {
    freeze_table.value = user.row.id;
    state.loading = 'resend';
    const { data } = await $services.users.resend_invite({
      organization_id: auth_store.current_organization?.uid,
      body: {
        invitations: [user.row.original.uid],
        asset: route?.params?.asset_id,
      },
    });
    if (data) {
      $toast({
        title: $t('Invitation sent'),
        text: route?.params?.asset_id
          ? $t('An email invitation has been sent to the users to join the asset')
          : $t('An email invitation has been sent to the users to join the organization'),
        type: 'success',
      });
      state.loading = '';
      freeze_table.value = '-1';
    }
    else {
      $toast({
        title: $t('Failed to invite users'),
        text: $t('Unable to invite users. Please double-check your input and try again'),
        type: 'error',
      });
      state.loading = '';
      freeze_table.value = '-1';
    }
  }
  catch (error) {
    $toast({
      title: $t('Failed to invite users'),
      text: $t('Unable to invite users. Please double-check your input and try again'),
      type: 'error',
    });
    state.loading = '';
    freeze_table.value = '-1';
  }
}

async function revokeUser(user) {
  try {
    freeze_table.value = user.row.id;
    state.loading = 'cancel';
    const { data } = await $services.users.revoke_invite({
      organization_id: auth_store.current_organization.uid,
      body: {
        invitations: [user.row.original.uid],
        asset: route.params.asset_id,
      },
    });

    if (data.status === 'success') {
      $toast({
        text: 'User cancelled successfully',
        type: 'success',
      });
      emit('update');
      state.loading = '';
      freeze_table.value = '-1';
    }

    else {
      $toast({
        title: 'Something went wrong',
        text: 'Please try again',
        type: 'error',
      });
      state.loading = '';
      freeze_table.value = '-1';
    }
  }
  catch (error) {
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
    });
    state.loading = '';
    freeze_table.value = '-1';
  }
}

onMounted(async () => {
  if (has_permission.value) {
    loadJsonEngine();
    await getData();
    onApplyDisplayFilters();
  }
});
</script>

<template>
  <HawkIllustrations v-if="!has_permission" type="no-permission" />
  <div v-else>
    <HawkPageSecondaryHeader class="my-4">
      <template #left>
        <div class="flex gap-3">
          <HawkDisplayFilters
            ref="display_filters$"
            :display_filters="display_filters"
            @apply="onApplyDisplayFilters"
          />
        </div>
      </template>
      <template #right>
        <HawkSearchInput
          v-model="state.search"
          :placeholder="$t('Search')"
          @update:modelValue="onApplyDisplayFilters"
        />
      </template>
    </HawkPageSecondaryHeader>

    <div v-if="filter_loading">
      <HawkLoader />
    </div>
    <div v-else-if="!filtered_data?.length">
      <HawkIllustrations v-if="state.search" type="no-results" for="invitations" />
      <HawkIllustrations v-else type="no-data" for="invitations" />
    </div>
    <div v-else>
      <TableWrapperVue container_class="!mt-0">
        <HawkTable
          :key="filtered_data.length"
          :pagination_config="{ totalRows: filtered_data.length, pageSize: 25 }"
          :data="filtered_data"
          :columns="table_columns"
          :show_menu_header="false"
          :freeze_table="freeze_table"
          is_gapless
        >
          <template v-for="row in state.invitations.filter(log => log.is_expanded)" :key="row.uid" #[`row_info_${row.uid}`]>
            <div>
              <div v-for="[key, value] in Object.entries(getInvitesDetail(row))" :key="key" class="flex text-xs text-gray-600 py-2">
                <span class="flex-none w-32"> {{ key }} </span>
                <div v-if="key === 'Type' && value" class="capitalize">
                  {{ value?.split('_')?.join(' ') }}
                </div>
                <div v-else-if="key === 'Assets' && value.length" class="flex flex-wrap">
                  <div v-for="(asset, index) in value" :key="asset" class="flex">
                    <HawkAssetName :uid="asset" :length="50" />
                    <div v-if="index !== value.length - 1" class="mr-2 text-sm">
                      ,
                    </div>
                  </div>
                </div>
                <div v-else-if="key === 'Teams' && value.length" class="flex flex-wrap">
                  <div v-for="(team, index) in value" :key="team" class="flex">
                    <HawkTeamName :uid="team" :length="50" />
                    <div v-if="index !== value.length - 1" class="mr-2 text-sm">
                      ,
                    </div>
                  </div>
                </div>
                <div v-else-if="key === 'Roles' && value.length" class="flex flex-wrap">
                  <div v-for="(role, index) in value" :key="role" class="flex">
                    <HawkRoleName :uid="role" :length="50" />
                    <div v-if="index !== value.length - 1" class="mr-2 text-sm">
                      ,
                    </div>
                  </div>
                </div>
                <template v-else>
                  -
                </template>
              </div>
            </div>
          </template>
          <template #email="email">
            <hawk-button
              icon
              type="plain"
              class="ml-[-15px]"
              @click.stop="toggleExpanded(email.data.row.original.uid)"
            >
              <IconHawkChevronDown v-if="email.data.row.original.is_expanded" />
              <IconHawkChevronRight v-else />
            </hawk-button>
            <div>
              {{ email.data.getValue() }}
            </div>
          </template>
          <template #requested_by="requested_by">
            <HawkMembers :members="requested_by.data.getValue()" type="badge" />
          </template>
          <template #created_at="created_at">
            {{ $date(created_at.data.getValue(), 'DATETIME_MED') }}
          </template>
          <template #status="{ data: { row: { original: { email_status } } } }">
            <HawkBadge v-if="email_status" :color="status_map[email_status]?.color">
              {{ status_map[email_status]?.title }}
            </HawkBadge>
          </template>
          <template #actions="{ data }">
            <div class="flex items-center justify-end gap-3 w-full text-gray-600">
              <HawkButton v-tippy="{ content: $t('Resend') }" :loading="state.loading === 'resend' && freeze_table === data.row.id" icon type="text" @click.stop="resendInvite(data)">
                <IconHawkSendOne />
              </HawkButton>
              <HawkButton v-tippy="{ content: $t('Cancel') }" :loading="state.loading === 'cancel' && freeze_table === data.row.id" icon type="text" @click.stop="revokeUser(data)">
                <IconHawkX />
              </HawkButton>
            </div>
          </template>
        </HawkTable>
      </TableWrapperVue>
    </div>
  </div>
</template>
