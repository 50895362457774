<script setup>
import { useRoute, useRouter } from 'vue-router';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import { clearAuthentication, setCookie } from '~/common/utils/common.utils';

const route = useRoute();
const router = useRouter();
const auth_store = useAuthStore();
const common_store = useCommonStore();
const $t = inject('$t');
const $toast = inject('$toast');
const $services = inject('$services');
const form = ref({});
const sign_in_method = ref('sso');
const is_loading = ref(false);
const toggle_password_type = ref(false);
const wrong_password_error = ref(false);
const account_locked_out = ref(false);
const pass$ = ref();

onMounted(() => {
  // This is to ensure whenever a user lands on sign-in, no matters if it's from redirection or direct, auth is always cleared.
  clearAuthentication();
});
async function onSignInClicked() {
  is_loading.value = true;
  localStorage.setItem('extended_session', form.value.remember_me);
  const from = route.query?.from || '/';

  try {
    if (sign_in_method.value === 'sso') {
      const { data } = await $services.auth.fetchWebFinger({
        email: form.value.email,
        from,
      });
      if (data.links[0].properties['okta:idp:type'] === 'SAML2')
        window.location.href = data.links[0].href;

      else
        sign_in_method.value = 'normal';
    }
    else {
      const { signed_in, response } = await auth_store.sign_in(form.value);

      if (response.data?.error?.includes?.('True') && response.data?.meta?.user_id) {
        $services.auth.resendCode({
          email: form.value.email,
          id: response.data.meta.user_id,
        });
        auth_store.sign_up_details.formData = form.value;
        auth_store.sign_up_details.uid = response.data?.meta?.user_id;
        router.push({
          name: 'verify-email',
        });
      }
      setCookie('signed_in_at_least_once', true);

      if (signed_in) {
        window?.fcWidget?.destroy?.();
        await common_store.initialize();
        auth_store.load_split();

        router.replace(from);
      }
      else if (response.errorCode === 'E0000004') {
        logger.log('Incorrect email address or password. Error: ', 'error');
        wrong_password_error.value = true;
      }
      else if (response.status === 'LOCKED_OUT') {
        wrong_password_error.value = false;
        account_locked_out.value = true;
      }
    }
  }
  catch (error) {
    logger.error(error);
  }
  finally {
    is_loading.value = false;
  }
}

async function onSignInWithGoogleClicked() {
  await auth_store.socialSignIn();
}

function isSignInDisabled() {
  const formData = form.value;
  if (!('password' in formData))
    return !formData.email || formData.email.trim().length === 0;

  wrong_password_error.value = false;
  return (!formData.password || formData.password.trim().length === 0);
}

watch(pass$, () => {
  pass$.value.focus();
});
async function loadOkta() {
  const { OktaAuth } = await import('@okta/okta-auth-js');
  window.OktaAuth = OktaAuth;
}
onBeforeMount(() => {
  loadOkta();
});
</script>

<template>
  <div class="min-h-screen">
    <div class="sm:mx-auto sm:w-full sm:max-w-md pt-24">
      <div class="flex justify-center mb-6">
        <HawkIconTaskmapperIcon />
      </div>
      <p class="text-center text-3xl font-semibold tracking-tight text-gray-900 mb-3">
        {{ $t('Welcome to TaskMapper') }}
      </p>
      <span class="text-gray-600">
        {{ $t('A Unified Platform for Solar Projects') }}
      </span>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow-[0_2px_16px_8px_rgba(112,117,126,0.08)] rounded-xl sm:px-10">
        <Vueform
          v-model="form" size="sm" :display-errors="false"
          :columns="{
            default: { container: 12, label: 12, wrapper: 12 },
            sm: { container: 12, label: 12, wrapper: 12 },
          }"
          @submit="onSignInClicked"
        >
          <div class="col-span-12 grid gap-4">
            <div class="flex justify-between col-span-12 relative">
              <TextElement
                :class="{ 'pointer-events-none': sign_in_method === 'normal' }"
                name="email"
                label="Email"
                input-type="email"
                rules="required|email"
                class="w-full"
                placeholder="Enter your email"
                :add-class="{
                  inputContainer: '!h-10',
                }"
              />
              <span
                v-if="sign_in_method === 'normal'"
                style="height:fit-content"
                class="text-blue-600 hover:text-blue-500 text-sm absolute right-0 cursor-pointer"
                @click="sign_in_method = 'sso'"
              >
                {{ $t('Change') }}
              </span>
            </div>
            <TextElement
              v-if="sign_in_method === 'normal'"
              ref="pass$"
              name="password"
              label="Password"
              rules="required"
              class="mt-1"
              placeholder="Enter password"
              :add-class="{
                inputContainer: '!h-10',
              }"
              :input-type="toggle_password_type ? 'text' : 'password'"
            >
              <template #addon-after>
                <div class="cursor-pointer w-8 h-8 grid place-items-center" @click.stop="toggle_password_type = !toggle_password_type">
                  <IconHawkEye v-if="toggle_password_type" class="h-4 w-4" />
                  <IconHawkEyeOff v-else class="h-4 w-4" />
                </div>
              </template>
              <template #description>
                <p v-if="wrong_password_error || account_locked_out" class="font-normal text-sm text-red-500 text-left ">
                  {{ account_locked_out ? $t("Too many login attempts. Please try again in 60 minutes.") : $t("Wrong password. Try again or click Forgot password to reset it.") }}
                </p>
              </template>
            </TextElement>
            <div class="flex justify-between col-span-12 py-2">
              <CheckboxElement name="remember_me" text="Remember me" class="text-gray-900 flex-1 flex-shrink-0" />
              <div class="flex-1 flex justify-end flex-shrink-0">
                <RouterLink class="text-blue-700 hover:text-blue-500 font-semibold text-sm" to="forgot-password">
                  {{ $t('Forgot password?') }}
                </RouterLink>
              </div>
            </div>
            <ButtonElement :disabled="false" name="submit" button-label="Sign in" size="md" :submits="true" :loading="is_loading" button-class="w-full bg-blue-600 !py-[9px] !text-sm" />
            <div v-if="!common_store.is_ril" class="w-full col-span-12 mx-auto">
              <div class="relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="w-full border-t border-gray-200" />
                </div>
                <div class="relative flex justify-center text-sm font-medium leading-6">
                  <span class="bg-white px-2 text-gray-500">Or</span>
                </div>
              </div>
            </div>
            <ButtonElement
              v-if="!common_store.is_ril"
              name="with google" size="md" button-class="w-full !text-base" :override-classes="{
                ButtonElement: {
                  button: '!bg-white',
                  button_primary: '!text-gray-700 !font-semibold !border !border-gray-300',
                  button_enabled: 'transition-none',
                },
              }"
              @click="onSignInWithGoogleClicked"
            >
              <span class="flex justify-center items-center">
                <HawkIconGoogle height="24" width="24" class="mr-3 " />
                <span class="text-sm">Sign in with Google</span>
              </span>
            </ButtonElement>
          </div>
        </Vueform>
      </div>
      <div class="py-12 text-gray-600 text-sm w-96 mx-auto">
        <div>
          {{ $t('By logging in to TaskMapper, you accept Sensehawk’s') }}
          <a href="https://sensehawk.com/" class="underline">{{ $t('Terms of Service') }}</a>,
          <a href="https://sensehawk.com/" class="underline">{{ $t('Privacy Policy') }}</a>
          and <a href="https://sensehawk.com/" class="underline"> {{ $t('Cookie Policy') }}</a>.
        </div>
        <div class="my-3">
          © Sensehawk, Inc.
        </div>
      </div>
    </div>
  </div>
</template>
